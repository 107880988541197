import React from "react";
import jwt_decode from "jwt-decode";

const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

interface AuthContextType {
  token: string;
  username: string;
  signin: (user: string, callback?: VoidFunction) => void;
  signout: (callback?: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider(props: { children: React.ReactNode }) {
  let [token, setToken] = React.useState("");
  const [username, setUserName] = React.useState("");

  let signin = (token: string, callback?: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(token);
      const decoded = jwt_decode<{ username: string }>(token);
      console.log(decoded);
      setUserName(decoded.username);
      callback && callback();
    });
  };

  let signout = (callback?: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      localStorage.removeItem("token");
      setToken("");
      callback && callback();
    });
  };

  let value = { token, username, signin, signout };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
