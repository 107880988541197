import rgba from "./rgba";
import pxToRem from "./pxToRem";

export default function boxShadow(
  offset: Array<number>,
  radius: Array<number>,
  color: string | undefined,
  opacity: number,
  inset = ""
) {
  if (color === undefined)
    throw new Error("function boxShadow cannot hanlde undefined color");
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread
  )} ${rgba(color, opacity)}`;
}
