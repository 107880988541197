import { GridSortModel } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { UpdateUserQuestionCorrectQidDto } from "../../dtos/correct-user-questions"
import {
  answerFromType,
  QuestionAnswerDto,
  ReviewDto,
  SortColumnsType,
  UserQuestionStatusDto,
} from "../../dtos/user-questions-response"
import useRoot from "../../hooks/useRoot"
import { Get, Post } from "../../uilts/request"

interface IDtoExtend extends ReviewDto {
  loading: boolean
  pageSize: number
  page: number
}

const useAction = () => {
  const { includeAboveAvg } = useRoot()
  const [dto, setDto] = useState<IDtoExtend>({
    loading: true,
    questionsForReview: [],
    rowCount: 0,
    pageSize: 15,
    page: 0,
  })

  const [updateQuestionDtos, setUpdateQuestionDtos] = useState<
    UpdateUserQuestionCorrectQidDto[]
  >([])

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const [userQuestionStatusDtos, setuserQuestionStatusDtos] = useState<
    UserQuestionStatusDto[]
  >([])

  const [selectedStatus, setSelectedStatus] = useState<number>(0)

  const [searchCorrectQid, setSearchCorrectQid] = useState<string>("")

  const [searchAskBy, setSearchAskBy] = useState<string>("")

  const [searchId, setSearchId] = useState<string>("")

  const [selectionModel, setSelectionModel] = useState<number[]>([])

  const [open, setOpen] = useState<boolean>(false)

  const [extensionsGenerateOpen, setExtensionsGenerateOpen] =
    useState<boolean>(false)

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)

  const [extensionsGenerateDto, setExtensionsGenerateDto] = useState<{
    correctQids: string
    minCount: number
    maxCount: number
  }>({
    correctQids: "",
    minCount: 0,
    maxCount: 0,
  })

  const [title, setTitle] = useState<string>("")

  const updateExtensionsGenerateDto = (
    k: keyof {
      correctQids: string
      minCount: number
      maxCount: number
    },
    v: number | string
  ) => {
    setExtensionsGenerateDto((prev) => ({ ...prev, [k]: v }))
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const updateData = (k: keyof IDtoExtend, v: any) =>
    setDto((prev) => ({ ...prev, [k]: v }))

  useEffect(() => {
    loadData()
  }, [dto.page, dto.pageSize, sortModel])

  const sortParameter = (): string => {
    if (dto.sortColumns && sortModel.length > 0) {
      var defaultItem = sortModel[0]
      var field = defaultItem.field as keyof SortColumnsType
      var param = `&sortField=${dto.sortColumns[field]}`
      if (defaultItem.sort) {
        param = `${param}&sortDirection=${defaultItem.sort}`
      }
      return param
    } else {
      return ""
    }
  }

  const loadData = () => {
    updateData("loading", true)

    Get<UserQuestionStatusDto[]>(`/api/v1/faqs/question/status`).then((res) => {
      if (res) setuserQuestionStatusDtos(res)
    })

    const skip = dto.page * dto.pageSize
    Get<ReviewDto>(
      `/api/v1/faqs/review${includeAboveAvg ? "/1" : ""}?skip=${skip}&take=${
        dto.pageSize
      }${sortParameter()}&status=${selectedStatus}&correct_qid=${searchCorrectQid}&askBy=${searchAskBy}&id=${searchId}`
    )
      .then((res) => {
        if (res) {
          setTimeout(() => {
            updateData("rowCount", res?.rowCount)
            updateData("questionsForReview", res.questionsForReview)
            updateData("sortColumns", res?.sortColumns)
            updateData("loading", false)
          }, 100)
        }
      })
      .catch((err) => {
        setTimeout(() => {
          updateData("sortColumns", 0)
          updateData("rowCount", 0)
          updateData("questionsForReview", [])
          updateData("loading", false)
        }, 100)
      })
  }

  useEffect(() => {
    if (updateQuestionDtos.length > 0) {
      updateQuestionDtos.forEach((item) =>
        dto.questionsForReview.forEach((findItem) => {
          if (item.id === findItem.id) {
            findItem.status = item.status
            findItem.correct_qid = item.correct_qid
            findItem.remark = item.remark
          }
        })
      )
      updateData("questionsForReview", dto.questionsForReview)
    }
  }, [updateQuestionDtos, dto.questionsForReview])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (callBack?: () => void) => {
    setOpen(false)
    callBack && callBack()
  }

  const onCancelGenerate = () => {
    setExtensionsGenerateOpen(false)
    updateExtensionsGenerateDto("correctQids", "")
    updateExtensionsGenerateDto("minCount", 0)
    updateExtensionsGenerateDto("maxCount", 0)
  }

  const questionsExtensionsGenerate = () => {
    if (!!extensionsGenerateDto.correctQids && extensionsGenerateDto.maxCount)
      Post("/api/v1/faqs/questions/extensions/generate", {
        qids: extensionsGenerateDto.correctQids.split(/[,，]/).filter(Boolean),
        minExtensionsCount: extensionsGenerateDto.minCount,
        maxExtensionsCount: extensionsGenerateDto.maxCount,
      })
        .then((res) => {
          setExtensionsGenerateOpen(false)
          loadData()
          updateExtensionsGenerateDto("correctQids", "")
          updateExtensionsGenerateDto("minCount", 0)
          updateExtensionsGenerateDto("maxCount", 0)
        })
        .catch((err) => {
          setTitle("生成失败!")
          setSnackbarOpen(true)
        })
    else {
      if (extensionsGenerateDto.correctQids.length <= 0) {
        setTitle("未填写生成数据的Correct Qid!")
      } else if (
        extensionsGenerateDto.maxCount <= 0 ||
        extensionsGenerateDto.maxCount < extensionsGenerateDto.minCount
      ) {
        setTitle("生成的数量有误!")
      }
      setSnackbarOpen(true)
    }
  }

  return {
    dto,
    setDto,
    loadData,
    updateQuestionDtos,
    setUpdateQuestionDtos,
    updateData,
    sortModel,
    setSortModel,
    searchCorrectQid,
    setSearchCorrectQid,
    selectedStatus,
    setSelectedStatus,
    userQuestionStatusDtos,
    searchAskBy,
    setSearchAskBy,
    searchId,
    setSearchId,
    selectionModel,
    setSelectionModel,
    open,
    handleClickOpen,
    handleClose,
    extensionsGenerateOpen,
    setExtensionsGenerateOpen,
    questionsExtensionsGenerate,
    extensionsGenerateDto,
    updateExtensionsGenerateDto,
    onCancelGenerate,
    title,
    snackbarOpen,
    handleSnackbarClose,
  }
}

export default useAction
