import { useState } from "react"

const useAction = (props:any) => {
  const [open, setOpen] = useState(false)
  const [remark, setRemark] = useState<string>(props.params.row.remark)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return {
    open, setOpen, remark, setRemark, handleClickOpen, handleClose
  }
}

export default useAction