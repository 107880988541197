export default function rgba(hex?: string, opacity?: number) {
  if (hex === undefined) throw new Error("function rgba hex is undefined");
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity ?? opacity})`;
}

function hex(color?: Object) {
  if (color === undefined) throw new Error("undefined color");
}
