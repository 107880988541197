import { createTheme } from "@mui/material/styles";
import palette from "./base/palette";
import breakpoints from "./base/breakpoints";
import typography from "./base/typography";
import boxShadows from "./base/boxShadows";
import borders from "./base/borders";
import globals from "./base/globals";
import boxShadow from "./functions/boxShadow";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";
import {
  BoxShadowsOption,
  BordersOption,
  FunctionOption,
  VariantTypography,
  TypographySizeOption,
  LineHeightOption,
  PaletteExtendsColor,
  PalettePrimordialColor,
} from "./module";

import drawer from "./components/drawer";
import list from "./components/list";
import listItem from "./components/list/listItem";
import listItemText from "./components/list/listItemText";
import card from "./components/card";
import cardMedia from "./components/card/cardMedia";
import cardContent from "./components/card/cardContent";
import button from "./components/button";
import iconButton from "./components/iconButton";
import input from "./components/form/input";
import inputLabel from "./components/form/inputLabel";
import inputOutlined from "./components/form/inputOutlined";
import textField from "./components/form/textField";
import menu from "./components/menu";
import menuItem from "./components/menu/menuItem";
import switchButton from "./components/form/switchButton";
import divider from "./components/divider";
import tableContainer from "./components/table/tableContainer";
import tableHead from "./components/table/tableHead";
import tableCell from "./components/table/tableCell";
import linearProgress from "./components/linearProgress";
import breadcrumbs from "./components/breadcrumbs";
import slider from "./components/slider";
import avatar from "./components/avatar";
import tooltip from "./components/tooltip";
import appBar from "./components/appBar";
import { tab, tabs } from "./components/tabs";
import stepper from "./components/stepper";
import step from "./components/stepper/step";
import stepConnector from "./components/stepper/stepConnector";
import stepLabel from "./components/stepper/stepLabel";
import stepIcon from "./components/stepper/stepIcon";
import select from "./components/form/select";
import formControlLabel from "./components/form/formControlLabel";
import formLabel from "./components/form/formLabel";
import checkbox from "./components/form/checkbox";
import radio from "./components/form/radio";
import autocomplete from "./components/form/autocomplete";
import container from "./components/container";
import popover from "./components/popover";
import buttonBase from "./components/buttonBase";
import icon from "./components/icon";
import svgIcon from "./components/svgIcon";
import link from "./components/link";
import dialog from "./components/dialog";
import dialogTitle from "./components/dialog/dialogTitle";
import dialogContent from "./components/dialog/dialogContent";
import dialogContentText from "./components/dialog/dialogContentText";
import dialogActions from "./components/dialog/dialogActions";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: boolean;
  }
  interface ButtonPropsColorOverrides {
    white: boolean;
    light: boolean;
    dark: boolean;
  }
}

declare module "@mui/material/TextField" {
  interface BaseTextFieldProps {
    success?: boolean;
  }
}

declare module "@mui/material/styles/createTypography.d" {
  interface Typography extends TypographySizeOption {}

  interface TypographyOptions
    extends Partial<
      Record<Variant | VariantTypography, TypographyStyleOptions> &
        FontStyleOptions &
        Record<"fontWeightLighter", number> &
        TypographySizeOption &
        LineHeightOption
    > {}
}

declare module "@mui/material/styles" {
  interface Palette extends PaletteOptions {}
  interface PaletteOptions extends PaletteExtendsColor {
    transparent: { main: string };
    sideBackground: string;
    tabs: { indicator: { boxShadow: string } };
    badgeColors: Record<
      PalettePrimordialColor,
      {
        background: string;
        text: string;
      }
    >;
    inputBorderColor: string;
  }

  interface PaletteColor extends PaletteColorOptions {}
  interface PaletteColorOptions {
    main: string;
    focus?: string;
    gradient?: {
      main: string;
      state: string;
    };
    coloredShadows?: string;
  }
}

declare module "@mui/material/styles" {
  interface ThemeOptions {
    boxShadows: BoxShadowsOption;
    borders: BordersOption;
    functions: FunctionOption;
  }

  interface Theme {
    boxShadows: BoxShadowsOption;
    borders: BordersOption;
    functions: FunctionOption;
  }
}

const baseTheme = createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...palette },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    linearGradient,
    pxToRem,
    rgba,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    MuiDrawer: { ...drawer },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiButton: { ...button },
    MuiIconButton: { ...iconButton },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiOutlinedInput: { ...inputOutlined },
    MuiTextField: { ...textField },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiSwitch: { ...switchButton },
    MuiDivider: { ...divider },
    MuiTableContainer: { ...tableContainer },
    MuiTableHead: { ...tableHead },
    MuiTableCell: { ...tableCell },
    MuiLinearProgress: { ...linearProgress },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiSlider: { ...slider },
    MuiAvatar: { ...avatar },
    MuiTooltip: { ...tooltip },
    MuiAppBar: { ...appBar },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiStepper: { ...stepper },
    MuiStep: { ...step },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiStepIcon: { ...stepIcon },
    MuiSelect: { ...select },
    MuiFormControlLabel: { ...formControlLabel },
    MuiFormLabel: { ...formLabel },
    MuiCheckbox: { ...checkbox },
    MuiRadio: { ...radio },
    MuiAutocomplete: { ...autocomplete },
    MuiPopover: { ...popover },
    MuiButtonBase: { ...buttonBase },
    MuiIcon: { ...icon },
    MuiSvgIcon: { ...svgIcon },
    MuiLink: { ...link },
    MuiDialog: { ...dialog },
    MuiDialogTitle: { ...dialogTitle },
    MuiDialogContent: { ...dialogContent },
    MuiDialogContentText: { ...dialogContentText },
    MuiDialogActions: { ...dialogActions },
  },
});

export default baseTheme;
