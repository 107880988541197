import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import UseAction from "./hook"

const Remark = (props: any) => {
  const { open, remark, setRemark, handleClickOpen, handleClose } =
    UseAction(props)

  const { handleCellEdit, params } = props

  return (
    <Box>
      <div
        style={{
          minWidth: "7.5rem",
          minHeight: "1.25rem",
        }}
        onClick={handleClickOpen}
      >
        {remark}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remark</DialogTitle>
        <DialogContent>
          <DialogContentText>Comment on this question!</DialogContentText>
          <TextField
            margin="dense"
            fullWidth
            autoFocus
            label="Remark"
            multiline
            rows={4}
            value={remark}
            onChange={(e) => {
              setRemark(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemark("")
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose()
              !!params.row.remark
                ? handleCellEdit(
                    params.row.id,
                    params.row.status,
                    params.row.correct_qid,
                    remark
                  )
                : !!remark &&
                  handleCellEdit(
                    params.row.id,
                    params.row.status,
                    params.row.correct_qid,
                    remark
                  )
            }}
          >
            Preservation
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Remark
