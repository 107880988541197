import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./layout/";
import routes, { IRouteItem } from "./routes";
import Main from "./layout/Main";
import LoginPage from "./pages/login";
import RequireAuth from "./pages/auth";
import { InitialAppSetting } from "./appsettings";
import { useEffect, useState } from "react";

export default function App() {
  const [init, setInit] = useState(false)
  useEffect(() => {
    InitialAppSetting().then(() => setInit(true))
  }, [])
  const AuthRoutes: any = (routes: IRouteItem[]) =>
    routes.map((route) => {
      if (route.collapse) {
        return AuthRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            path={route.route.path}
            element={
              <RequireAuth>
                <Main>{route.route.component}</Main>
              </RequireAuth>
            }
            key={route.key}
          />
        );
      }
      return null;
    });
  return (
    <div>
      {!init ? (
        ""
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<Layout />}>
            {AuthRoutes(routes)}
          <Route path="*" element={<Navigate to="/home" />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}
