import { Collapse, List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ItemBox from "./ItemBox";
import ItemIcon from "./ItemIcon";
import ItemText from "./ItemText";
import { IRouteItem } from "../../../routes";

export default function NavItem(props: { item: IRouteItem }) {
  const { item } = props;
  const [open, setOpen] = useState<boolean>(false);

  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const baseItem = (value: IRouteItem) => {
    let active = collapseName === value.key;
    return (
      <ListItem
        key={value.key}
        component="li"
        onClick={() => value.collapse && setOpen(!open)}
      >
        <ItemBox
          ownerState={{
            active: active,
            transparentSidenav: false,
            whiteSidenav: false,
            darkMode: false,
          }}
        >
          <ItemIcon
            ownerState={{
              active: active,
              transparentSidenav: false,
              whiteSidenav: false,
              darkMode: false,
            }}
          >
            {value.icon && value.icon}
          </ItemIcon>
          <ItemText
            primary={value.name}
            ownerState={{
              active: active,
              transparentSidenav: false,
              whiteSidenav: false,
            }}
          />
        </ItemBox>
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      {item.route ? (
        <NavLink key={item.name} to={item.route.path}>
          {baseItem(item)}
        </NavLink>
      ) : (
        baseItem(item)
      )}
      {item.collapse && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {item.collapse.map((m) => {
              return m.route ? (
                <NavLink key={m.name} to={m.route.path}>
                  {baseItem(m)}
                </NavLink>
              ) : (
                baseItem(m)
              );
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}
