import { useState } from "react"

const useAction = () => {
  const [globalTootip, setGlobalTootip] = useState("loading...")

  const [updateStatus, setUpdateStatus] = useState<number>(-1)

  const [updateCorrectQid, setUpdateCorrectQid] = useState<string>("")

  return {
    globalTootip,
    setGlobalTootip,
    updateStatus,
    setUpdateStatus,
    updateCorrectQid,
    setUpdateCorrectQid,
  }
}

export default useAction
