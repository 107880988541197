import { useState } from "react";

const useAction = () => {
  const [showHistoryQuestion, setShowHistoryQuestion] = useState(false);
  return {
    showHistoryQuestion,
    setShowHistoryQuestion,
  };
};

export default useAction;
