import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Snackbar,
} from "@mui/material";
import LineCard from "./componets/LineCard";
import PieCard from "./componets/PieCard";
import MDIconCard from "../../components/MDCard/MDIconCard";
import MDTypography from "../../components/MDTypography";
import "react-day-picker/dist/style.css";
import "./react-day-picker.css";
import { useAction } from "./hook";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";
import Draggable from "react-draggable";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LoopIcon from "@mui/icons-material/Loop";
import { LoadingButton } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";

export default function DashboardPage() {
  const {
    accuracySerie,
    avgSerie,
    countSerie,
    behaviourTypeCount,
    open,
    handleClickOpen,
    handleClose,
    range,
    setRange,
    handleSnackbarClose,
    snackbarOpen,
    title,
    isLoading,
    loadBehaviourEnum,
    selectedMonth,
    handleDayClick,
    pieData,
    isLoadDown,
  } = useAction();

  const iconList: string[] = [
    "question_answer",
    "translate",
    "photo",
    "article",
    "article",
    "wysiwyg",
  ];

  const paperComponent = (props: PaperProps) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const performances = (
    <>
      <Grid item xs={12} md={6} lg={4}>
        {accuracySerie && (
          <LineCard
            bgColor="info"
            data={accuracySerie}
            title="Model Accuracy"
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {avgSerie && (
          <LineCard
            bgColor="success"
            data={avgSerie}
            title="Correct Intent Confidence(AVG)"
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {countSerie && <LineCard data={countSerie} title="Number of Samples" />}
      </Grid>
    </>
  );

  const footerRender = (text: string) => (
    <MDTypography variant="button">{text}</MDTypography>
  );

  const chooseColor = (index: number) => {
    switch (Math.floor(index % 4)) {
      case 0:
        return "info" as PalettePrimordialColor;
      case 1:
        return "primary" as PalettePrimordialColor;
      case 2:
        return "success" as PalettePrimordialColor;
      case 3:
        return "warning" as PalettePrimordialColor;
    }
  };

  const iconRow = (
    <Grid
      container
      item
      rowSpacing={3}
      columnSpacing={2}
      sx={{ position: "relative" }}
    >
      {!isLoadDown && (
        <CircularProgress
          color="info"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        />
      )}
      {behaviourTypeCount.length > 0 &&
        behaviourTypeCount.map((x, index) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <MDIconCard
                color={chooseColor(index + 1)}
                icon={iconList[index]}
                title={x.text}
                count={x.count}
                footer={footerRender(x.description)}
              />
            </Grid>
          );
        })}
    </Grid>
  );

  const pie = (
    <Grid container item direction="row">
      <PieCard
        data={pieData}
        width={600}
        height={400}
        bgcolor={"black"}
        title={"Metis Response Count"}
      />
    </Grid>
  );

  return (
    <>
      <div style={{ marginBottom: "1.5rem" }}>
        <Button
          variant="outlined"
          sx={{
            color: "#1A73E8",
            marginRight: "1rem",
            borderColor: "#1A73E8 !important",
          }}
          onClick={handleClickOpen}
        >
          Select Date Range
        </Button>
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          loadingPosition="start"
          startIcon={<LoopIcon />}
          sx={{
            color: "#1A73E8",
            borderColor: "#1A73E8 !important",
          }}
          onClick={() => loadBehaviourEnum()}
        >
          Loading
        </LoadingButton>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={paperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Date
          </DialogTitle>

          <DialogContent sx={{ height: 400 }}>
            <DialogContentText>
              {`从 ${
                range?.from
                  ? format(range?.from, "yyyy/MM/dd HH:mm")
                  : "StartTime"
              } 到 ${
                range?.to ? format(range?.to, "yyyy/MM/dd HH:mm") : "EndTime"
              }`}
            </DialogContentText>

            <DayPicker
              mode="range"
              defaultMonth={selectedMonth}
              selected={range}
              onDayClick={handleDayClick}
              showOutsideDays
              pagedNavigation
              fixedWeeks
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => handleClose("Cancel")}>
              Cancel
            </Button>
            <Button onClick={() => handleClose("Save")}>Subscribe</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={title}
          action={action}
        />
      </div>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid container item>
          {iconRow}
        </Grid>
        {pie}
        {performances}
      </Grid>
    </>
  );
}
