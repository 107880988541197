import { ListItemIcon, styled } from "@mui/material";

export interface SideItemIconProps {
  active: boolean;
  transparentSidenav?: boolean;
  whiteSidenav?: boolean;
  darkMode?: boolean;
}

export default styled(ListItemIcon)<{ ownerState: SideItemIconProps }>(
  ({ theme, ownerState }) => {
    const { palette, transitions, borders, functions } = theme;
    const { transparentSidenav, whiteSidenav, darkMode, active } = ownerState;

    const { dark } = palette;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
      minWidth: pxToRem(32),
      minHeight: pxToRem(32),
      color:
        (transparentSidenav && !darkMode && !active) ||
        (whiteSidenav && !active)
          ? dark.main
          : palette.common.white,
      borderRadius: borderRadius.md,
      display: "grid",
      placeItems: "center",
      transition: transitions.create("margin", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      "& svg, svg g": {
        color:
          transparentSidenav || whiteSidenav ? dark.main : palette.common.white,
      },
    };
  }
);
