import { useContext } from "react";
import { MaterialUIContext } from "./providers/MaterialUIControllerProvider";

function useMaterialUIController() {
  const context = useContext(MaterialUIContext);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }
  return context;
}

const setMiniSidenav = (dispatch: any, value: boolean) =>
  dispatch({ type: "MINI_SIDENAV", payload: value });
const setTransparentSidenav = (dispatch: any, value: boolean) =>
  dispatch({ type: "TRANSPARENT_SIDENAV", payload: value });
const setWhiteSidenav = (dispatch: any, value: boolean) =>
  dispatch({ type: "WHITE_SIDENAV", payload: value });
const setSidenavColor = (dispatch: any, value: string) =>
  dispatch({ type: "SIDENAV_COLOR", payload: value });
const setTransparentNavbar = (dispatch: any, value: boolean) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", payload: value });
const setFixedNavbar = (dispatch: any, value: boolean) =>
  dispatch({ type: "FIXED_NAVBAR", payload: value });
const setOpenConfigurator = (dispatch: any, value: boolean) =>
  dispatch({ type: "OPEN_CONFIGURATOR", payload: value });
const setDirection = (dispatch: any, value: boolean) =>
  dispatch({ type: "DIRECTION", payload: value });
const setLayout = (dispatch: any, value: string) =>
  dispatch({ type: "LAYOUT", payload: value });
const setDarkMode = (dispatch: any, value: boolean) =>
  dispatch({ type: "DARKMODE", payload: value });

export {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
