import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "./sideBar";
import { Backdrop, CircularProgress } from "@mui/material";
import useRoot from "../hooks/useRoot";
import {
  setMiniSidenav,
  useMaterialUIController,
} from "../hooks/useMaterialUIController";
import routes from "../routes";
import useAuth from "../hooks/useAuth";
//import brandWhite from "../assets/images/a";
//import brandDark from "../assets/images/logo-ct-dark.png";

export default function Layout() {
  const { username } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { doingBusyWork } = useRoot();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <SideBar
        accountName={username?.toUpperCase()} //"Smart-Faq"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        logoIcon={""}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={doingBusyWork}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Outlet />
    </>
  );
}
