import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AliveScope } from "react-activation";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import defaultTheme from "./assets/defaultTheme";
import AuthProvider from "./hooks/providers/authProvider";
import RootProvider from "./hooks/providers/rootProvider";
import requestInterceptor from "./uilts/httpClient";
import MaterialUIControllerProvider from "./hooks/providers/MaterialUIControllerProvider";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <AuthProvider>
          <RootProvider>
            <AliveScope>
              <App />
            </AliveScope>
          </RootProvider>
        </AuthProvider>
      </ThemeProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
requestInterceptor();
