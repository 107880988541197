import { createContext, useReducer } from "react";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";

type State = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: PalettePrimordialColor;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: string;
  layout: string;
  darkMode: boolean;
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.payload };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.payload };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.payload };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.payload };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.payload };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.payload };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.payload };
    }
    case "DIRECTION": {
      return { ...state, direction: action.payload };
    }
    case "LAYOUT": {
      return { ...state, layout: action.payload };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

export type IMaterialUI = [controller: State, dispatch: any];

export const MaterialUIContext = createContext<IMaterialUI>([
  {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  },
  undefined,
]);

export default function MaterialUIControllerProvider(props: { children: any }) {
  const init: State = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const _value = useReducer(reducer, init);

  return (
    <MaterialUIContext.Provider value={_value}>
      {props.children}
    </MaterialUIContext.Provider>
  );
}
