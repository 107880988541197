import { PaletteOptions } from "@mui/material/styles";
import { PaletteExtendsColor } from "../module";

const extendsValue: PaletteExtendsColor = {
  primary: {
    main: "#e91e63",
    focus: "#e91e63",
    gradient: {
      main: "#EC407A",
      state: "#D81B60",
    },
    coloredShadows: "#e91e62",
  },
  secondary: {
    main: "#7b809a",
    focus: "#8f93a9",
    coloredShadows: "#110e0e",
    gradient: { main: "#747b8a", state: "#495361" },
  },
  error: {
    main: "#F44335",
    focus: "#f65f53",
    gradient: { main: "#EF5350", state: "#E53935" },
    coloredShadows: "#f44336",
  },
  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
    gradient: { main: "#FFA726", state: "#FB8C00" },
    coloredShadows: "#ff9900",
  },
  info: {
    main: "#1A73E8",
    focus: "#1662C4",
    gradient: { main: "#49a3f1", state: "#1A73E8" },
    coloredShadows: "#00bbd4",
  },
  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
    gradient: { main: "#66BB6A", state: "#43A047" },
    coloredShadows: "#4caf4f",
  },
  dark: {
    main: "#344767",
    focus: "#2c3c58",
    gradient: { main: "#42424a", state: "#191919" },
    coloredShadows: "#404040",
  },
  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
    gradient: { main: "#EBEFF4", state: "#CED4DA" },
    coloredShadows: "#adb5bd",
  },
};

const tabs = {
  indicator: { boxShadow: "#ddd" },
};

const palette: PaletteOptions = {
  ...extendsValue,
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  grey: {
    50: "#fafafa",
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },
  text: {
    primary: "#7b809a",
    secondary: "#7b809a",
  },
  background: {
    default: "#f0f2f5",
  },
  sideBackground: "#1f283e",
  transparent: {
    main: "transparent",
  },
  tabs: tabs,
  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },
  inputBorderColor: "#d2d6da",
};

// socialMediaColors: {
//   facebook: {
//     main: "#3b5998",
//     dark: "#344e86",
//   },

//   twitter: {
//     main: "#55acee",
//     dark: "#3ea1ec",
//   },

//   instagram: {
//     main: "#125688",
//     dark: "#0e456d",
//   },

//   linkedin: {
//     main: "#0077b5",
//     dark: "#00669c",
//   },

//   pinterest: {
//     main: "#cc2127",
//     dark: "#b21d22",
//   },

//   youtube: {
//     main: "#e52d27",
//     dark: "#d41f1a",
//   },

//   vimeo: {
//     main: "#1ab7ea",
//     dark: "#13a3d2",
//   },

//   slack: {
//     main: "#3aaf85",
//     dark: "#329874",
//   },

//   dribbble: {
//     main: "#ea4c89",
//     dark: "#e73177",
//   },

//   github: {
//     main: "#24292e",
//     dark: "#171a1d",
//   },

//   reddit: {
//     main: "#ff4500",
//     dark: "#e03d00",
//   },

//   tumblr: {
//     main: "#35465c",
//     dark: "#2a3749",
//   },
// },

// badgeColors: {
//   primary: {
//     background: "#f8b3ca",
//     text: "#cc084b",
//   },

//   secondary: {
//     background: "#d7d9e1",
//     text: "#6c757d",
//   },

//   info: {
//     background: "#aecef7",
//     text: "#095bc6",
//   },

//   success: {
//     background: "#bce2be",
//     text: "#339537",
//   },

//   warning: {
//     background: "#ffd59f",
//     text: "#c87000",
//   },

//   error: {
//     background: "#fcd3d0",
//     text: "#f61200",
//   },

//   light: {
//     background: "#ffffff",
//     text: "#c7d3de",
//   },

//   dark: {
//     background: "#8097bf",
//     text: "#1e2e4a",
//   },
// },

// inputBorderColor: "#d2d6da",

// tabs: {
//   indicator: { boxShadow: "#ddd" },
// },

export default palette;
