import { ReactElement } from "react";
import { AnswerProps } from "../../../../dtos/predict-response";
import {
  AnswerContainer,
  AnswerContent,
  AnswerTitle,
} from "../../index.styles";

const AnswerElement: React.FC<AnswerProps> = (
  props: AnswerProps
): ReactElement => {
  return (
    <AnswerContainer key={props.answer.qid}>
      <AnswerTitle>{props.answer.title}</AnswerTitle>(<span>Qid:</span>
      <span>{props.answer.qid}</span>
      <span>Confidence:</span>
      <span>{props.answer.confidence}</span>)
      <AnswerContent
        dangerouslySetInnerHTML={{ __html: props.answer.answer_content }}
      />
    </AnswerContainer>
  );
};

export default AnswerElement;
