import { useEffect, useState } from "react"

import MDBox from "../../components/MDBox"
import MDTypography from "../../components/MDTypography"
import MDButton from "../../components/MDButton"
import { Card, Grid, Switch, Link as MuiLink } from "@mui/material"

import bgImage from "../../assets/images/bg-sign-in-basic.jpeg"
import MDInput from "../../components/MDInput"
import useAuth from "../../hooks/useAuth"
import { Post } from "../../uilts/request"
import { useLocation, useNavigate } from "react-router-dom"
import MDSnackbar from "../../components/MDSnackbar"

export default function LoginPage() {
  const { signin } = useAuth()
  let navigate = useNavigate()
  let location = useLocation()
  const [userName, setuserName] = useState("")
  const [password, setPassword] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [remember, setRemember] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    var token = localStorage.getItem("token")
    if (token) {
      signin(token, historyCallback)
    }
  }, [])

  useEffect(() => {
    setDisabled(userName === "" || password === "")
  }, [userName, password])

  const handleClick = () => {
    Post<string>("/auth/login", {
      username: userName,
      password: password,
    }).then((token) => {
      if (token) {
        if (remember) {
          localStorage.setItem("token", token)
        }
        signin(token, historyCallback)
      } else {
        setError(true)
      }
    })
  }

  const historyCallback = () => {
    let p = location.state as any
    p?.from?.pathname
      ? navigate(p.from.pathname, { replace: true })
      : navigate("/home")
  }

  const closeError = () => setError(false)
  const renderError = (
    <MDSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      color="error"
      icon="warning"
      title="Error"
      detailContent="Incorrect username or password."
      open={error}
      onClose={closeError}
      bgWhite
    />
  )

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      sx={{ overflowX: "hidden" }}
    >
      {renderError}
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette }) =>
            `${linearGradient(
              rgba(palette.dark.gradient?.main, 0.6),
              rgba(palette.dark.gradient?.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Sign in
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      label="UserName"
                      fullWidth
                      onChange={(e) => setuserName(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={password}
                      type="password"
                      label="Password"
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch
                      //checked={false}
                      checked={remember}
                      onChange={(e) => {
                        setRemember(e.target.checked)
                      }}
                    />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={disabled}
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleClick}
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  )
}
