import colors from "../../base/palette"
import borders from "../../base/borders"
import boxShadows from "../../base/boxShadows"
import pxToRem from "../../functions/pxToRem"
import linearGradient from "../../functions/linearGradient"

const { transparent } = colors
const { borderRadius } = borders
const { colored } = boxShadows

const stepper = {
  styleOverrides: {
    root: {
      background: linearGradient(
        colors.info?.gradient?.main,
        colors.info?.gradient?.state
      ),
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borderRadius.lg,
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
}
export default stepper
