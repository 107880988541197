import Fade from "@mui/material/Fade";
import colors from "../base/palette";
import typography from "../base/typography";
import borders from "../base/borders";
import pxToRem from "../functions/pxToRem";

const { common, light } = colors;
const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const tooltip = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: common?.black,
      color: light.main,
      fontSize: size?.sm,
      fontWeight: fontWeightRegular,
      textAlign: "center" as "center",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
    },

    arrow: {
      color: common?.black,
    },
  },
};
export default tooltip;
