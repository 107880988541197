import { MenuItem, Select } from "@mui/material"

const SelectStatus = (props: any) => {
  const { handleCellEdit, params, questionStatus } = props

  return (
    <Select
      onChange={(e) => {
        handleCellEdit(
          params.row.id,
          e.target.value as number,
          params.row.correct_qid,
          params.row.remark
        )
      }}
      value={params.row.status as number}
    >
      {questionStatus.map((item: any, key: any) => (
        <MenuItem key={key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectStatus
