import { forwardRef, PropsWithChildren } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  BordersType,
  BoxShadowsType,
  PalettePrimordialColor,
} from "../../assets/defaultTheme/module";

export interface MDBoxProps extends BoxProps {
  variant?: "contained" | "gradient";
  bgColor?: PalettePrimordialColor | "white" | "black" | "transparent" | "text";
  color?: PalettePrimordialColor | "white" | "inherit" | "text";
  opacity?: number;
  borderRadius?: BordersType | number;
  shadow?: BoxShadowsType;
  coloredShadow?: PalettePrimordialColor;
  to?: string;
  width?: string | number;
  src?: "";
}

const MDBoxRoot = styled(Box)<{ ownerState: MDBoxProps }>(
  ({ theme, ownerState }) => {
    const { palette, functions, borders, boxShadows } = theme;
    const {
      variant,
      bgColor,
      color,
      opacity,
      borderRadius,
      shadow,
      coloredShadow,
    } = ownerState;

    const { common } = palette;
    const { linearGradient } = functions;
    const { colored } = boxShadows;

    let backgroundValue = "transparent";

    if (variant === "gradient") {
      if (
        bgColor === "white" ||
        bgColor === "black" ||
        bgColor === "transparent" ||
        bgColor === "text" ||
        bgColor === undefined
      ) {
        backgroundValue = common.white;
      } else {
        let gradient = palette[bgColor].gradient;
        backgroundValue = linearGradient(gradient?.main, gradient?.state);
      }
    } else if (bgColor === "white" || bgColor === "black") {
      backgroundValue = common[bgColor];
    } else if (bgColor === "transparent") {
      backgroundValue = palette.transparent.main;
    } else if (bgColor === "text") {
      backgroundValue = palette.text.primary;
    } else if (bgColor === undefined) {
      backgroundValue = "transparent";
    } else {
      let gradient = palette[bgColor].gradient;
      backgroundValue = linearGradient(gradient?.main, gradient?.state);
    }

    let colorValue =
      color === undefined
        ? undefined
        : color === "white"
        ? palette.common.white
        : color === "text"
        ? palette.text.primary
        : color === "inherit"
        ? "inherit"
        : palette[color].main;

    let boxShadowValue = "none";
    if (shadow) {
      boxShadowValue = boxShadows.size[shadow];
    } else if (coloredShadow) {
      boxShadowValue = colored[coloredShadow] ? colored[coloredShadow] : "none";
    }

    let borderRadiusValue = undefined;
    if (typeof borderRadius === "number") {
      borderRadiusValue = `${(borderRadius * 100).toString()}%`;
    } else if (borderRadius !== undefined) {
      borderRadiusValue = borders.borderRadius[borderRadius];
    }

    return {
      opacity,
      background: backgroundValue,
      color: colorValue,
      borderRadius: borderRadius && borderRadiusValue,
      boxShadow: boxShadowValue,
    };
  }
);

const MDBox = forwardRef((props: PropsWithChildren<MDBoxProps>, ref) => {
  const {
    children,
    variant,
    bgColor,
    color,
    opacity,
    borderRadius,
    shadow,
    coloredShadow,
    ...rest
  } = props;
  return (
    <MDBoxRoot
      {...rest}
      ref={ref}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
        coloredShadow,
      }}
    >
      {children}
    </MDBoxRoot>
  );
});

export default MDBox;
