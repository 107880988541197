import { styled } from "@mui/material";
import MDBox from "../../../components/MDBox";

export interface SideItemBoxProps {
  active: boolean;
  transparentSidenav?: boolean;
  whiteSidenav?: boolean;
  darkMode?: boolean;
}

export default styled(MDBox)<{ ownerState: SideItemBoxProps }>(
  ({ theme, ownerState }) => {
    const {
      palette,
      transitions,
      breakpoints,
      boxShadows,
      borders,
      functions,
    } = theme;
    const { active, transparentSidenav, whiteSidenav, darkMode } = ownerState;

    const { transparent, dark, grey } = palette;
    const { borderRadius } = borders;
    const { pxToRem, rgba, linearGradient } = functions;

    let gradient = palette.info.gradient;
    return {
      background: active
        ? linearGradient(gradient?.main, gradient?.state)
        : transparent.main,
      color:
        (transparentSidenav && !darkMode && !active) ||
        (whiteSidenav && !active)
          ? dark.main
          : palette.common.white,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: `${pxToRem(8)} ${pxToRem(10)}`,
      margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      cursor: "pointer",
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow:
        active && !whiteSidenav && !darkMode && !transparentSidenav
          ? boxShadows.size.md
          : "none",
      [breakpoints.up("xl")]: {
        transition: transitions.create(["box-shadow", "background-color"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.shorter,
        }),
      },

      "&:hover, &:focus": {
        backgroundColor: () => {
          let backgroundValue;

          if (!active) {
            backgroundValue =
              transparentSidenav && !darkMode
                ? grey[300]
                : rgba(whiteSidenav ? grey[400] : palette.common.white, 0.2);
          }

          return backgroundValue;
        },
      },
    };
  }
);
