import { useEffect, useState } from "react";
import { Get, Post } from "../../uilts/request";
import {
  IConversation,
  IConversationForDetail,
  IConversationGroupResponseDto,
  IUserQuestion,
  LlmDto,
  SampleStatusType,
  SampleType,
  StatusType,
  UserQuestionLImRequestDto,
} from "../../dtos/gpt-train-response";
import { clone } from "ramda";
import { GridSortModel } from "@mui/x-data-grid";
import useRoot from "../../hooks/useRoot";
import { useDebounceFn } from "ahooks";

interface IDtoExtend extends LlmDto {
  loading: boolean;
  pageSize: number;
  page: number;
}

interface IConversationGroupDto extends IConversationGroupResponseDto {
  loading: boolean;
  open: boolean;
  modifieSampleType: SampleType | null;
}

interface IAddConversationDto {
  open: boolean;
  orignalConversation: IConversation | null;
  addConversation: IConversation | null;
  orignalIsShow: boolean;
  orignalTimeOut: NodeJS.Timeout | null;
  addIsShow: boolean;
  addTimeOut: NodeJS.Timeout | null;
  addSampleStatus: SampleStatusType | null;
}

interface IEditConversationDto {
  open: boolean;
  conversation: IConversationForDetail | null;
  isShow: boolean;
  timeOut: NodeJS.Timeout | null;
}

interface IDeleteConversationDto {
  open: boolean;
  conversationId: number | null;
}

export const useAction = () => {
  const { setMessage } = useRoot();

  const [dto, setDto] = useState<IDtoExtend>({
    page: 0,
    pageSize: 15,
    loading: true,
    rowCount: 0,
    userQuestionLlmRequests: [],
  });

  // prompt的dto
  const [promptDialog, setPromptDialog] = useState<{
    status: boolean;
    data: string[] | null;
    id: number | null;
  }>({
    status: false,
    data: null,
    id: null,
  });

  // 要更新的LLM数据
  const [updateLlmData, setUpdateLlmData] = useState<
    UserQuestionLImRequestDto[]
  >([]);

  // LLM table的搜索参数
  const [searchDto, setSearchDto] = useState<{
    status: StatusType;
    groupCode: number | null;
  }>({
    status: StatusType.Pending,
    groupCode: null,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [conversationGroupDto, setConversationGroupDto] =
    useState<IConversationGroupDto>({
      open: false,
      loading: false,
      groupId: null,
      groupCode: null,
      sampleType: null,
      conversations: [],
      createDate: null,
      conversationRowCount: null,
      modifieSampleType: null,
    });

  const [addConversationDto, setAddConversationDto] =
    useState<IAddConversationDto>({
      open: false,
      orignalConversation: null,
      addConversation: null,
      orignalIsShow: false,
      orignalTimeOut: null,
      addIsShow: false,
      addTimeOut: null,
      addSampleStatus: null,
    });

  const [editConversationDto, setEditConversationDto] =
    useState<IEditConversationDto>({
      open: false,
      conversation: null,
      isShow: false,
      timeOut: null,
    });

  const [deleteConversationDto, setDeleteConversationDto] =
    useState<IDeleteConversationDto>({
      open: false,
      conversationId: null,
    });

  // 获取LLM List
  const loadData = (
    page: number,
    pageSize: number,
    sortModel: GridSortModel
  ) => {
    updateData("loading", true);

    Get<LlmDto>(
      `/api/v1/faqs/llm/review?Page=${
        page + 1
      }&PageSize=${pageSize}${sortParameter(sortModel)}&Status=${
        searchDto.status
      }${searchDto.groupCode ? `&GroupCode=${searchDto.groupCode}` : ""}`
    )
      .then((res) => {
        if (res) {
          setTimeout(() => {
            updateData(
              "userQuestionLlmRequests",
              res?.userQuestionLlmRequests ?? []
            );
            updateData("rowCount", res?.rowCount ?? 0);
            updateData("loading", false);
          }, 100);
        }
      })
      .catch(() => {
        setTimeout(() => {
          updateData("userQuestionLlmRequests", []);
          updateData("rowCount", 0);
          updateData("loading", false);
        }, 100);
      });
  };

  const updateListValue = <K extends keyof UserQuestionLImRequestDto>(
    id: number,
    k: K,
    value: string | number
  ) => {
    const newValue = clone(dto.userQuestionLlmRequests);

    const findItem = newValue.find((x) => x.id === id)!;

    if (k in findItem) {
      findItem[k] = (
        k === "groupCode" ? Number(value) : value
      ) as UserQuestionLImRequestDto[K];
    }

    setUpdateLlmData((prev) => {
      const findIndex = prev.findIndex(
        (x) => x.questionId === findItem.questionId
      );

      if (findIndex > -1) {
        prev[findIndex] = findItem;

        return prev;
      } else {
        return [...prev, findItem];
      }
    });

    updateData("userQuestionLlmRequests", newValue);
  };

  const updateSearchDto = (
    k: keyof { status: StatusType; groupCode: string },
    v: StatusType | number | null
  ) => setSearchDto((prev) => ({ ...prev, [k]: v }));

  const updateData = (k: keyof IDtoExtend, v: any) =>
    setDto((prev) => ({ ...prev, [k]: v }));

  const updatePromptDialog = (
    k: keyof {
      status: boolean;
      data: string[] | null;
      id: number | null;
    },
    v: any
  ) =>
    setPromptDialog((prev) => ({
      ...prev,
      [k]: v,
    }));

  const updateConversationGroupDto = (k: keyof IConversationGroupDto, v: any) =>
    setConversationGroupDto((prev) => ({ ...prev, [k]: v }));

  const updateAddConversationDto = (k: keyof IAddConversationDto, v: any) =>
    setAddConversationDto((prev) => ({ ...prev, [k]: v }));

  const updateEditConversationDto = (k: keyof IEditConversationDto, v: any) =>
    setEditConversationDto((prev) => ({ ...prev, [k]: v }));

  const updateDeleteConversationDto = (
    k: keyof IDeleteConversationDto,
    v: any
  ) => setDeleteConversationDto((prev) => ({ ...prev, [k]: v }));

  const sortParameter = (sortModel: GridSortModel): string => {
    if (sortModel.length > 0) {
      var param = `&sortField=${
        sortModel[0].field as keyof UserQuestionLImRequestDto
      }`;

      if (sortModel[0].sort) {
        param = `${param}&sortDirection=${sortModel[0].sort}`;
      }

      return param;
    } else {
      return "";
    }
  };

  const onSaveClick = () => {
    if (updateLlmData.length) {
      const data = updateLlmData.map((item) => ({
        id: item.id,
        questionId: item.questionId,
        groupCode: item.groupCode,
        model: item.model,
        createdDate: item.createdDate,
        responseJson: item.responseJson,
        requestJson: item.requestJson,
        provider: item.provider,
        status: item.status,
      }));

      Post("/api/v1/faqs/llm/update", {
        userQuestionLlmDataList: data,
      })
        .then(() => {
          loadData(0, dto.pageSize, sortModel);
          setUpdateLlmData([]);
        })
        .catch((err) => {
          setMessage("error", "error", "register", (err as Error).message);
        });
    }
  };

  const updateConversationGroupAll = (
    groupId: number | null,
    groupCode: number | null,
    sampleType: SampleType | null,
    createDate: number | null,
    conversationRowCount: number | null,
    conversations: IConversation[]
  ) => {
    updateConversationGroupDto("groupId", groupId);
    updateConversationGroupDto("groupCode", groupCode);
    updateConversationGroupDto("sampleType", sampleType);
    updateConversationGroupDto("modifieSampleType", sampleType);
    updateConversationGroupDto("createDate", createDate);
    updateConversationGroupDto("conversationRowCount", conversationRowCount);
    updateConversationGroupDto("conversations", conversations);
  };

  const loadConversationGroupDto = (groupCode: number) => {
    updateConversationGroupDto("loading", true);

    Get<IConversationGroupResponseDto>(
      `/api/v1/faqs/llm/group?GroupCode=${groupCode}`
    )
      .then((res) => {
        updateConversationGroupAll(
          res?.groupId ?? null,
          res?.groupCode ?? null,
          res?.sampleType ?? null,
          res?.createDate ?? null,
          res?.conversationRowCount ?? 0,
          (res?.conversations ?? []).sort((a, b) => {
            return a.isOriginal === b.isOriginal ? 0 : a.isOriginal ? -1 : 1;
          }) ?? []
        );
      })
      .catch(() => {
        updateConversationGroupAll(null, null, null, null, 0, []);
      })
      .finally(() => {
        updateConversationGroupDto("loading", false);
      });
  };

  // 添加对话
  const addConversation = () => {
    const originalItem = conversationGroupDto.conversations.find(
      (item) => item.isOriginal
    );

    if (originalItem) {
      updateAddConversationDto("open", true);
      updateAddConversationDto("orignalConversation", originalItem);
      updateAddConversationDto("addConversation", originalItem);
    }
  };

  // 更新SampleType
  const { run: changeConversationSampleType } = useDebounceFn(
    () => {
      if (
        conversationGroupDto.groupId &&
        conversationGroupDto.groupCode &&
        conversationGroupDto.sampleType !==
          conversationGroupDto.modifieSampleType
      ) {
        Post("/api/v1/faqs/llm/conversation/sample", {
          groupId: conversationGroupDto.groupId,
          code: conversationGroupDto.groupCode,
          sampleType: conversationGroupDto.modifieSampleType,
        })
          .then(() => {
            loadConversationGroupDto(conversationGroupDto.groupCode!);
          })
          .catch(() => {
            setMessage("error", "error", "", "Sample Type更新失败");
          });
      } else {
        setMessage(
          "warning",
          "info",
          "",
          "Sample Type跟原本的一致，无需更新！"
        );
      }
    },
    {
      wait: 300,
    }
  );

  const { run: editConversationOpen } = useDebounceFn(
    (conversationId: number) => {
      Get<IConversationForDetail>(
        `/api/v1/faqs/llm/conversation?ConversationId=${conversationId}`
      )
        .then((res) => {
          if (res) {
            updateEditConversationDto("conversation", {
              conversationId: res?.conversationId ?? conversationId,
              groupId: res?.groupId,
              sampleStatus: res?.sampleStatus ?? null,
              userQuestions: res?.userQuestions ?? [],
              conversationRowCount: res?.conversationRowCount ?? null,
            });
            updateEditConversationDto("open", true);
          } else {
            throw new Error("获取对话详情失败");
          }
        })
        .catch(() => {
          setMessage("error", "error", "", "获取对话详情失败");
        });
    },
    {
      wait: 300,
    }
  );

  const { run: deleteConversationFn } = useDebounceFn(
    (conversationId: number) => {
      Post("/api/v1/faqs/llm/conversation/delete", {
        conversationId,
      })
        .then(() => {
          updateDeleteConversationDto("open", false);
          conversationGroupDto.groupCode &&
            loadConversationGroupDto(conversationGroupDto.groupCode);
        })
        .catch(() => {
          setMessage("error", "error", "", "删除对话失败");
        });
    },
    {
      wait: 300,
    }
  );

  const addConversationAdd = () => {
    const newData = clone(addConversationDto);

    if (newData.addConversation) {
      newData.addConversation.userQuestions.push({
        question: "",
        answer: "",
      });

      updateAddConversationDto("addConversation", newData.addConversation);
    }
  };

  const addConversationUpdate = (
    i: number,
    k: keyof IUserQuestion,
    v: string
  ) => {
    const newData = clone(addConversationDto);

    if (newData.addConversation) {
      newData.addConversation.userQuestions[i] = {
        ...newData.addConversation.userQuestions[i],
        [k]: v,
      };

      updateAddConversationDto("addConversation", newData.addConversation);
    }
  };

  const addConversationRemove = (i: number) => {
    const newData = clone(addConversationDto);

    if (newData.addConversation) {
      newData.addConversation.userQuestions =
        newData.addConversation.userQuestions.filter((_, index) => index !== i);
    }

    updateAddConversationDto("addConversation", newData.addConversation);
  };

  const { run: addConversationSave } = useDebounceFn(
    () => {
      if (
        addConversationDto.orignalConversation &&
        addConversationDto.addConversation &&
        conversationGroupDto.groupId &&
        conversationGroupDto.groupCode
      ) {
        const data = [
          {
            groupId: conversationGroupDto.groupId,
            conversationId:
              addConversationDto.orignalConversation.conversationId,
            llmQuestions:
              addConversationDto.orignalConversation.userQuestions.map(
                (item) => ({
                  question: item.question,
                  answer: item.answer,
                })
              ),
            sampleStatus: addConversationDto.orignalConversation.sampleStatus,
            isCopy: false,
          },
          {
            groupId: conversationGroupDto.groupId,
            conversationId:
              addConversationDto.orignalConversation.conversationId,
            llmQuestions: addConversationDto.addConversation.userQuestions.map(
              (item) => ({
                question: item.question,
                answer: item.answer,
              })
            ),
            sampleStatus: addConversationDto.addSampleStatus,
            isCopy: true,
          },
        ];

        Post("/api/v1/faqs/llm/labeling", {
          labelingUserQuestions: data,
        })
          .then(() => {
            updateAddConversationDto("open", false);

            loadConversationGroupDto(conversationGroupDto.groupCode!);
          })
          .catch(() => {
            setMessage("error", "error", "", "新增对话失败");
          });
      } else {
        // 无法提交
        setMessage("warning", "info", "", "还有参数未填写，请填写完再提交！");
      }
    },
    { wait: 300 }
  );

  // 暂时不需要 编辑新增
  const editConversationAdd = () => {
    const newData = clone(editConversationDto);

    if (newData.conversation) {
      newData.conversation.userQuestions.push({
        question: "",
        answer: "",
      });

      updateEditConversationDto("conversation", newData.conversation);
    }
  };

  const editConversationUpdate = (
    i: number,
    k: keyof IUserQuestion,
    v: string
  ) => {
    const newData = clone(editConversationDto);

    if (newData.conversation) {
      newData.conversation.userQuestions[i] = {
        ...newData.conversation.userQuestions[i],
        [k]: v,
      };

      updateEditConversationDto("conversation", newData.conversation);
    }
  };

  const editConversationUpdateSampleStatus = (
    sampleStatus: SampleStatusType | null
  ) => {
    const newData = clone(editConversationDto);

    if (newData.conversation) {
      newData.conversation.sampleStatus = sampleStatus;

      updateEditConversationDto("conversation", newData.conversation);
    }
  };

  // 暂时不需要 编辑删除
  const editConversationRemove = (i: number) => {
    const newData = clone(editConversationDto);

    if (newData.conversation) {
      newData.conversation.userQuestions =
        newData.conversation.userQuestions.filter((_, index) => index !== i);
    }

    updateEditConversationDto("conversation", newData.conversation);
  };

  const { run: editConversationSave } = useDebounceFn(
    () => {
      if (editConversationDto.conversation) {
        const data = {
          conversationId: editConversationDto.conversation.conversationId,
          llmQuestions: editConversationDto.conversation.userQuestions,
          sampleStatus: editConversationDto.conversation.sampleStatus,
        };

        Post("/api/v1/faqs/llm/question/update", data)
          .then(() => {
            updateEditConversationDto("open", false);
            loadConversationGroupDto(conversationGroupDto.groupCode!);
          })
          .catch(() => {
            setMessage("error", "error", "", "编辑对话失败");
          });
      }
    },
    { wait: 300 }
  );

  // 首次进入请求LLM数据
  useEffect(() => {
    loadData(dto.page, dto.pageSize, sortModel);
  }, [dto.page, dto.pageSize, sortModel]);

  useEffect(() => {
    if (conversationGroupDto.groupCode && conversationGroupDto.open) {
      loadConversationGroupDto(conversationGroupDto.groupCode);
    } else if (!conversationGroupDto.open) {
      updateConversationGroupAll(null, null, null, null, 0, []);
    }
  }, [conversationGroupDto.groupCode, conversationGroupDto.open]);

  // 清除添加的Conversation
  useEffect(() => {
    if (!addConversationDto.open) {
      updateAddConversationDto("orignalConversation", null);
      updateAddConversationDto("addConversation", null);
      updateAddConversationDto("addSampleStatus", null);
    }
  }, [addConversationDto.open]);

  // 清除编辑的Conversation
  useEffect(() => {
    if (!editConversationDto.open) {
      updateEditConversationDto("conversation", null);
    }
  }, [editConversationDto.open]);

  // 清除删除的ConversationId
  useEffect(() => {
    if (!deleteConversationDto.open) {
      updateDeleteConversationDto("conversationId", null);
    }
  }, [deleteConversationDto.open]);

  return {
    dto,
    setMessage,
    promptDialog,
    sortModel,
    searchDto,
    updateLlmData,
    conversationGroupDto,
    editConversationDto,
    addConversationDto,
    deleteConversationDto,
    updateData,
    setSortModel,
    updatePromptDialog,
    updateListValue,
    loadData,
    updateSearchDto,
    onSaveClick,
    addConversation,
    editConversationOpen,
    addConversationAdd,
    addConversationUpdate,
    addConversationRemove,
    addConversationSave,
    deleteConversationFn,
    editConversationAdd,
    editConversationUpdate,
    editConversationRemove,
    editConversationSave,
    changeConversationSampleType,
    updateAddConversationDto,
    updateEditConversationDto,
    updateConversationGroupDto,
    updateDeleteConversationDto,
    editConversationUpdateSampleStatus,
  };
};
