import typography from "../../base/typography";
import colors from "../../base/palette";

const { size } = typography;
const { text } = colors;

const dialogContentText = {
  styleOverrides: {
    root: {
      fontSize: size?.md,
      color: text?.primary,
    },
  },
};
export default dialogContentText;
