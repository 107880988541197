import React, { useState } from "react";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";
import MDSnackbar, { MDSnackbarProps } from "../../components/MDSnackbar";

type GlobalContent = {
  doingBusyWork: boolean;
  includeAboveAvg: boolean;
  setDoingBusyWork: (isBusy: boolean) => void;
  setIncludeAboveAvgUserQuestionsFilter: (include: boolean) => void;
  setMessage: (
    color: PalettePrimordialColor,
    icon: React.ReactNode,
    title: string,
    detailContent: React.ReactNode
  ) => void;
};
export const RootContext = React.createContext<GlobalContent>(null!);

export default function RootProvider(props: { children: React.ReactNode }) {
  const [isBusy, setDoingBusyWork] = useState<boolean>(false);
  const [snackcfg, setSnackcfg] = useState<MDSnackbarProps>({
    anchorOrigin: { vertical: "top", horizontal: "center" },
    color: "info",
    icon: "error",
    title: "",
    detailContent: "",
    open: false,
    onClose: () => setSnackcfg((snackcfg) => ({ ...snackcfg, open: false })),
  });

  const [includeAboveAvg, setIncludeAboveAvgUserQuestionsFilter] =
    useState<boolean>(false);

  const [showConfidencesOnReviewPage, setShowConfidencesOnReviewPage] =
    useState<boolean>(false);

  const message = (
    color: PalettePrimordialColor,
    icon: React.ReactNode,
    title: string,
    detailContent: React.ReactNode
  ) =>
    setSnackcfg({
      ...snackcfg,
      color: color,
      icon: icon,
      title: title,
      detailContent: detailContent,
      open: true,
    });

  return (
    <RootContext.Provider
      value={{
        doingBusyWork: isBusy,
        includeAboveAvg: includeAboveAvg,
        setDoingBusyWork: setDoingBusyWork,
        setIncludeAboveAvgUserQuestionsFilter:
          setIncludeAboveAvgUserQuestionsFilter,
        setMessage: message,
      }}
    >
      <MDSnackbar {...snackcfg} />
      {props.children}
    </RootContext.Provider>
  );
}
