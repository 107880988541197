import MDBox from "../../../../components/MDBox";
import { SearchContainer, SearchInput } from "../../index.styles";
import useAction from "./hook";
import style from "./index.module.scss";

export interface SearchElementProps {
  value: string;
  onSubmit(questionName: string): void;
  onChange(value: string): void;
  historyQuestion: Array<string>;
}

export default function SearchElement(props: SearchElementProps) {
  const { showHistoryQuestion, setShowHistoryQuestion } = useAction();
  const { value, onSubmit, onChange, historyQuestion } = props;

  return (
    <MDBox
      sx={{ marginTop: "50px" }}
      onBlur={() => {
        setShowHistoryQuestion(false);
      }}
    >
      <SearchContainer
        className={style.search}
        onKeyUp={(event: any) => {
          if (event.which === 13) {
            onSubmit(value);
            setShowHistoryQuestion(false);
            event.target.blur();
          }
        }}
      >
        <SearchInput
          label="Enter your query"
          variant="outlined"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onClick={() => setShowHistoryQuestion(true)}
        />
        <div className={style.history}>
          {showHistoryQuestion && historyQuestion.length > 0 ? (
            historyQuestion?.map((item, key) => (
              <div
                className={style.box}
                key={key}
                onMouseDown={() => {
                  onChange(item);
                  setShowHistoryQuestion(false);
                  onSubmit(item);
                }}
              >
                {item}
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </SearchContainer>
    </MDBox>
  );
}
