import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import { Container } from "@mui/material";
import MDInput from "../../components/MDInput";

const AppBox = styled("div")(() => {
  return {
    textAlign: "center",
    fontSize: "15px",
  } as CSSObject;
});

const Welcome = styled("div")(() => {
  return {
    fontSize: "25px",
  } as CSSObject;
});

const SearchContainer = styled(Container)(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    "& > :first-of-type": {
      marginRight: "10px",
    },
  } as CSSObject;
});

const SearchInput = styled(MDInput)(() => {
  return {
    width: "70%",
    marginRight: "10px",
  } as CSSObject;
});

const AnswerContainer = styled(Container)(() => {
  return {
    width: "100%",
    span: {
      marginRight: "3px",
      fontSize: "small",
    },
  } as CSSObject;
});

const AnswerTitle = styled("span")(() => {
  return {
    color: "#1a0dab",
  } as CSSObject;
});

const AnswerContent = styled("div")(() => {
  return {
    color: "#4d5156",
  } as CSSObject;
});

export {
  AppBox,
  Welcome,
  SearchContainer,
  SearchInput,
  AnswerContainer,
  AnswerTitle,
  AnswerContent,
};
