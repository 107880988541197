import colors from "../../base/palette";

const { text } = colors;
const formLabel = {
  styleOverrides: {
    root: {
      color: text?.primary,
    },
  },
};

export default formLabel;
