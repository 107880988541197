import React, { forwardRef } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Link, MenuItem } from "@mui/material";

interface MDNotificationItemProps {
  icon?: React.ReactNode;
  title: string;
  onClick?(): any;
}
const MDNotificationItem = forwardRef(
  (props: MDNotificationItemProps, ref: React.Ref<HTMLLIElement>) => {
    const { onClick, icon, title, ...rest } = props;
    return (
      <MenuItem
        {...rest}
        ref={ref}
        sx={(theme) => {
          const { palette, borders, transitions } = theme;
          const { secondary, light, dark } = palette;
          const { borderRadius } = borders;

          return {
            display: "flex",
            alignItems: "center",
            width: "100%",
            color: secondary.main,
            borderRadius: borderRadius.md,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),

            "& *": {
              transition: "color 100ms linear",
            },

            "&:not(:last-child)": {
              mb: 1,
            },

            "&:hover": {
              backgroundColor: light.main,

              "& *": {
                color: dark.main,
              },
            },
          };
        }}
      >
        <MDBox
          component={Link}
          py={0.5}
          display="flex"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
            {icon}
          </MDTypography>
          <MDTypography
            color="dark"
            variant="button"
            fontWeight="regular"
            sx={{ ml: 1 }}
            onClick={onClick}
          >
            {title}
          </MDTypography>
        </MDBox>
      </MenuItem>
    );
  }
);
export default MDNotificationItem;
