import useAuth from "../../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth(props: { children: JSX.Element }) {
  let { token } = useAuth();
  let location = useLocation();

  if (token === "") {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }

  return props.children;
}
