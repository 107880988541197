const { fetch: originalFetch } = window;

export default function requestInterceptor() {
  window.fetch = async (...args) => {
    let [resource, config] = args;
    let token = localStorage.getItem("token");
    if (token && token !== "" && !resource.toString().includes("/auth/login")) {
      config!.headers! = {
        Authorization: "Bearer " + token,
      };
    }
    const response = await originalFetch(resource, config);

    return response;
  };
}

export function responseInterceptor() {
  window.fetch = async (...args) => {
    let [resource, config] = args;
    let response = await originalFetch(resource, config);
    return response;
  };
}
