import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";
import rgba from "../../functions/rgba";
import { common } from "@mui/material/colors";

const { size, fontWeightRegular } = typography;

const stepLabel = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightRegular,
      fontSize: size?.xs,
      color: "#9fc9ff",
      textTransform: "uppercase" as "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(common?.white, 0.8)} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(common?.white, 0.8)} !important`,
      },
    },
  },
};
export default stepLabel;
