export interface LlmDto {
  rowCount: number;
  userQuestionLlmRequests: UserQuestionLImRequestDto[];
}

export interface UserQuestionLImRequestDto {
  id: number;
  model: string;
  askBy: string;
  answer: string;
  questionId: number;
  question: string;
  createdDate: number;
  responseJson: string;
  requestJson: string;
  groupCode: number;
  provider: number;
  sampleType: SampleType;
  sampleStatus: SampleStatusType;
  prompts: string[];
  status: StatusType;
}

export interface IConversationGroupResponseDto {
  groupId: number | null;
  groupCode: number | null;
  sampleType: SampleType | null;
  conversations: IConversation[];
  createDate: number | null;
  conversationRowCount: number | null;
}

export interface IConversation {
  conversationId: number;
  sampleStatus: SampleStatusType | null;
  userQuestions: IUserQuestion[];
  questionRowCount: number;
  isOriginal: boolean;
  isShow?: boolean;
  timeOut?: NodeJS.Timeout | null;
}

export interface IConversationForDetail {
  groupId: number;
  conversationId: number;
  sampleStatus: SampleStatusType | null;
  userQuestions: IUserQuestion[];
  conversationRowCount: number | null;
}

export interface IUserQuestion {
  id?: number;
  answer: string;
  question: string;
  createdDate?: number;
}

export enum TrainingType {
  Chosen = 0,
  Rejected = 1,
}

export enum StatusType {
  Pending = 10,
  ForGpt = 20,
  Noise = 30,
}

export enum ProviderType {
  OpenAi = 0,
  Hi200 = 1,
}

export enum SmapleMarkType {
  Chosen = 0,
  Rejected = 1,
}

export enum RoleType {
  User = 0,
  System = 1,
  Assistant = 2,
}

export enum SampleStatusType {
  Chosen = 0,
  Rejected = 1,
}

export enum SampleType {
  Translation = 0,
}

export const providerTypeTitle = {
  [ProviderType.OpenAi]: "OpenAi",
  [ProviderType.Hi200]: "Hi200",
};

export const sampleTypeTitle = {
  [SampleType.Translation]: "Translation",
};
