import { TextField } from "@mui/material";
import { InputProps } from "./props";

export const InputComponent = (props: InputProps) => {
  const { id, value, k, onChange } = props;

  return (
    <TextField
      variant="standard"
      name={`text-${id}`}
      value={value}
      onChange={(e) => {
        onChange(id, k, e.target.value);
      }}
    />
  );
};
