import colors from "../../base/palette"
import borders from "../../base/borders"
import boxShadows from "../../base/boxShadows"
import rgba from "../../functions/rgba"

const { common } = colors
const { borderWidth, borderRadius } = borders

const card = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column" as "column",
      position: "relative" as "relative",
      minWidth: 0,
      wordWrap: "break-word" as "break-word",
      backgroundColor: common?.white,
      backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(common?.black, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: boxShadows.size.md,
      overflow: "visible",
    },
  },
}
export default card
