import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MyBlockquote from "../../components/MyBlockquote";
import useRoot from "../../hooks/useRoot";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import { useEffect, useState } from "react";
import { Post } from "../../uilts/request";
import MDInput from "../../components/MDInput";

interface RegisterDto {
  username: string;
  password: string;
}

export default function SettingPage() {
  const {
    includeAboveAvg,
    setIncludeAboveAvgUserQuestionsFilter,
    setDoingBusyWork,
    setMessage,
  } = useRoot();

  const [selectedModel, setModel] = useState<string>("");
  const [data, setData] = useState<RegisterDto>({
    username: "",
    password: "",
  });
  const [validated, setValidated] = useState<boolean>(false);
  const upattern = /^[a-zA-Z0-9_-]{4,16}$/;
  const ppattern =
    /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;

  useEffect(() => {
    setValidated(ppattern.test(data.password));
  }, [data]);

  const typographyForIncludeAboveAvg = includeAboveAvg ? (
    <MDTypography variant="h6">
      Currently including user questions with confidence that above avaerge
    </MDTypography>
  ) : (
    <MDTypography variant="h6">
      User questions with confidence that above average will <b>NOT</b> be
      included
    </MDTypography>
  );

  const reevaluate = () => {
    setDoingBusyWork(true);
    Post(`/api/v1/faqs/questions/reevaluate`, { forModel: selectedModel }).then(
      () => setDoingBusyWork(false)
    );
  };

  const onModelSelected = (model: string) => {
    setModel(model);
  };

  const reculculate = () => {
    setDoingBusyWork(true);
    Post(`/api/v1/models/performance/calculate`).then(() =>
      setDoingBusyWork(false)
    );
  };

  const handleRegister = async () => {
    try {
      const res = await Post("/auth/register", data);
      res && setMessage("info", "", "register", "successful");
    } catch (err) {
      setMessage("error", "error", "register", (err as Error).message);
    }
  };

  return (
    <MDBox>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {typographyForIncludeAboveAvg}
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeAboveAvg}
                  onClick={() =>
                    setIncludeAboveAvgUserQuestionsFilter(!includeAboveAvg)
                  }
                />
              }
              label="Include above avg user qustions on Review screen"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography variant="h6">
            Re-evaluate the user questions, run this after you have a new model
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MyBlockquote content="NOTE: This process usually takes long, just be patient and we will add a process indicator soon"></MyBlockquote>
          <RadioGroup defaultValue="">
            <FormControlLabel
              value=""
              control={<Radio onChange={(_, value) => onModelSelected("")} />}
              label="All"
            />
            <FormControlLabel
              value="modelr"
              control={
                <Radio onChange={(_, value) => onModelSelected("modelr")} />
              }
              label="Model R"
            />
            <FormControlLabel
              value="modelq"
              control={
                <Radio onChange={(_, value) => onModelSelected("modelq")} />
              }
              label="Model Q"
            />
            <FormControlLabel
              value="model3"
              control={
                <Radio onChange={(_, value) => onModelSelected("model3")} />
              }
              label="Model 3"
            />
          </RadioGroup>
          <MDButton variant="gradient" color="info" onClick={reevaluate}>
            Evaluate now
          </MDButton>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography variant="h6">
            Re-calculate the model's performance, run this after you have
            finished the re-evaluation
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MDButton variant="gradient" color="info" onClick={reculculate}>
            Calculate now
          </MDButton>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography variant="h6">Register Account</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox sx={{ width: "25%" }}>
            <Stack spacing={2}>
              <MDInput
                value={data.username}
                type="text"
                label="USERNAME"
                onChange={(evt) =>
                  setData({ ...data, username: evt.target.value })
                }
              />
              <MDInput
                error={data.password === ""}
                value={data.password}
                type="password"
                helperText="最少6位,包括至少1个大写字母,1个小写字母,1个数字,1个特殊字符"
                onChange={(evt) =>
                  setData({ ...data, password: evt.target.value })
                }
              />
              <MDButton
                disabled={!validated}
                variant="gradient"
                color="info"
                onClick={handleRegister}
              >
                Register
              </MDButton>
            </Stack>
          </MDBox>
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
}
