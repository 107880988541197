import palette from "../base/palette";
import borders from "../base/borders";
import pxToRem from "../functions/pxToRem";

const { common } = palette;
const { borderRadius } = borders;

const drawer = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap" as "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: common?.white,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default drawer;
