import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Breadcrumbs as MuiBreadcrumbs, Icon } from "@mui/material";
import { Link } from "react-router-dom";

interface MDBreadcrumbsProps {
  icon: React.ReactNode;
  title: string;
  route: string[];
  light?: boolean;
}

export default function MDBreadcrumbs(props: MDBreadcrumbsProps) {
  const { icon, title, route, light } = props;
  const routes = route.slice(0, -1);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={(theme) => {
          return {
            "& .MuiBreadcrumbs-separator": {
              color: light ? "#ffffff" : theme.palette.grey[600],
            },
          };
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </MDTypography>
          </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography>
    </MDBox>
  );
}
