export interface MyBlockquoteProps {
  content: string
}

const MyBlockquote = (props: MyBlockquoteProps) => {
  //const styles = blockquoteStyles()
  return <div>{props.content}</div>
}

export default MyBlockquote
