import { Divider } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import {
  MDCard,
  MDCardBody,
  MDCardHeader,
  MDCardHeaderProps,
} from "../../../components/MDCard";
import MDTypography from "../../../components/MDTypography";

interface PieCardProps extends MDCardHeaderProps {
  data: any[];
  height?: string | number;
  title?: string;
}
export default function PieCard(props: PieCardProps) {
  const { data, height, title, bgColor, ...rest } = props;

  return (
    <MDCard>
      <MDCardHeader bgColor={bgColor} {...rest}>
        <MDTypography>{title}</MDTypography>
      </MDCardHeader>
      <MDCardBody>
        <div style={{ height: height ?? 250 }}>
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
          />
        </div>
      </MDCardBody>
      <Divider />
    </MDCard>
  );
}
