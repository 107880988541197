import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import MDBox from "../../../../components/MDBox"
import MDButton from "../../../../components/MDButton"
import { MDCardAction } from "../../../../components/MDCard"
import { DataGrid, GridCellParams, GridSelectionModel } from "@mui/x-data-grid"
import useAction from "./hook"
import enumArray from "enum-array"
import { Get, Post } from "../../../../uilts/request"
import MDTooltip from "../../../../components/MDTooltip"
import { AskQuestionDto } from "../../../../dtos/ask-question-dto"
import { UpdateUserQuestionCorrectQidDto } from "../../../../dtos/correct-user-questions"
import { UserQuestionStatus } from "../../../../dtos/user-question-status"
import EditableTextField from "./components/editable-textfield"
import SelectStatus from "./components/select-status"
import Remark from "./components/remark"
import React from "react"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  AnswerFrom,
  answerFromType,
  AnswerType,
  answerTypeTitle,
  QuestionAnswerDto,
  UserQuestionDto,
} from "../../../../dtos/user-questions-response"

const ReviewMain = React.memo((props: any) => {
  const {
    dto,
    setDto,
    loadData,
    updateData,
    sortModel,
    setSortModel,
    searchCorrectQid,
    setSearchCorrectQid,
    selectedStatus,
    setSelectedStatus,
    userQuestionStatusDtos,
    setUpdateQuestionDtos,
    updateQuestionDtos,
    searchAskBy,
    setSearchAskBy,
    searchId,
    setSearchId,
    selectionModel,
    setSelectionModel,
    open,
    handleClickOpen,
    handleClose,
    extensionsGenerateOpen,
    setExtensionsGenerateOpen,
    extensionsGenerateDto,
    updateExtensionsGenerateDto,
    questionsExtensionsGenerate,
    onCancelGenerate,
  } = props

  const {
    globalTootip,
    setGlobalTootip,
    updateStatus,
    setUpdateStatus,
    updateCorrectQid,
    setUpdateCorrectQid,
  } = useAction()

  const questionStatus = enumArray.getEnumArray(UserQuestionStatus)

  const asyncTootip = (params: GridCellParams, field: string) => {
    return (
      <MDTooltip
        title={globalTootip}
        onOpen={() => {
          loadTitle(params.row[field])
        }}
        onClose={() => setGlobalTootip("loading...")}
      >
        <span>{params.formattedValue}</span>
      </MDTooltip>
    )
  }

  const ShowTooltip = (props: { questionAnswers: QuestionAnswerDto[] }) => {
    const { questionAnswers } = props

    return (
      <Box>
        {questionAnswers.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              flex: 1,
              marginBottom: index === questionAnswers.length - 1 ? 0 : 1,
            }}
          >
            <span>
              {!!item.answer ? item?.answer : answerTypeTitle[item?.answerType]}
            </span>
          </Box>
        ))}
      </Box>
    )
  }

  const onSearchClick = () => {
    loadData()
  }

  const maxLengthInQuestions = Math.max(
    ...dto.questionsForReview.map((el: UserQuestionDto) => el.question.length)
  )
  const minWidthForQuestion = 400
  const maxWidthForQuestion = 504
  const questionActualWidth = maxLengthInQuestions * 14 + 14

  const maxLengthInAskBy = Math.max(
    ...dto.questionsForReview.map(
      (el: UserQuestionDto) => !!el.askBy && el.askBy && el.askBy.length
    )
  )
  const minWidthForAskBy = 120
  const maxWidthForAskBy = 200
  const askByActualWidth = maxLengthInAskBy * 10 + 12

  let columns = [
    { field: "id", headerName: "ID", width: 80 },
    {
      field: "question",
      headerName: "Question",
      type: "string",
      width:
        questionActualWidth > minWidthForQuestion
          ? questionActualWidth < maxWidthForQuestion
            ? questionActualWidth
            : maxWidthForQuestion
          : minWidthForQuestion,
      renderCell: (params: GridCellParams) => {
        let width = 0
        if (!!params.colDef.width) {
          width = params.colDef.width
        }
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <MDTooltip title={params.formattedValue} placement="bottom-start">
              <span
                style={{
                  width: width - 30,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {params.formattedValue}
              </span>
            </MDTooltip>
            <MDTooltip
              title={
                params.row.answers.length > 0 ? (
                  <ShowTooltip questionAnswers={params.row.answers} />
                ) : (
                  ""
                )
              }
              placement="bottom-start"
            >
              <VisibilityIcon />
            </MDTooltip>
          </Box>
        )
      },
    },
    {
      field: "askBy",
      headerName: "Ask By",
      type: "string",
      width:
        askByActualWidth > minWidthForAskBy
          ? askByActualWidth < maxWidthForAskBy
            ? askByActualWidth
            : maxWidthForAskBy
          : minWidthForAskBy,
    },
    {
      field: "answers",
      headerName: "Answer From",
      type: "number",
      width: 110,
      renderCell: (params: any) => {
        switch (params.row.answers[0]?.answerFrom) {
          case AnswerFrom.Hi200:
            return answerFromType[AnswerFrom.Hi200]
          case AnswerFrom.Metis:
            return answerFromType[AnswerFrom.Metis]
          default:
            return ""
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "string",
      width: 160,
      renderCell: (params: any) => {
        return (
          <span>{new Date(params.row.createdAt * 1000).toLocaleString()}</span>
        )
      },
    },
    {
      field: "model3Confidence",
      headerName: "Similarity",
      type: "number",
      width: 100,
    },
    {
      field: "modelVConfidence",
      headerName: "V Distance",
      type: "number",
      width: 100,
      renderCell: (params: GridCellParams) => {
        const decimalPart = String(params.row.modelVConfidence).split(".")[1]
        const length = decimalPart ? decimalPart.length : 0

        return (1 - params.row.modelVConfidence).toFixed(length)
      },
    },
    {
      field: "modelVPredictedQid",
      headerName: "V Pred",
      type: "number",
      width: 100,
      renderCell: (params: GridCellParams) =>
        asyncTootip(params, "modelVPredictedQid"),
    },
    {
      field: "rasaPredictedQid",
      headerName: "R Pred",
      type: "number",
      width: 90,
      renderCell: (params: GridCellParams) =>
        asyncTootip(params, "rasaPredictedQid"),
    },
    {
      field: "anyqPredictedQid",
      headerName: "Q Pred",
      type: "number",
      width: 90,
      renderCell: (params: GridCellParams) =>
        asyncTootip(params, "anyqPredictedQid"),
    },
    {
      field: "model3PredictedQid",
      headerName: "M3 Pred",
      type: "number",
      width: 90,
      renderCell: (params: GridCellParams) =>
        asyncTootip(params, "model3PredictedQid"),
    },
    {
      field: "correct_qid",
      headerName: "Correct Qid",
      width: 100,
      renderCell: (params: any) => (
        <EditableTextField
          params={params}
          handleCellEdit={handleCellEdit}
          globalTootip={globalTootip}
          loadTitle={loadTitle}
          setGlobalTootip={setGlobalTootip}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      renderCell: (params: any) => (
        <SelectStatus
          params={params}
          handleCellEdit={handleCellEdit}
          questionStatus={questionStatus}
        />
      ),
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 120,
      renderCell: (params: any) => (
        <Remark params={params} handleCellEdit={handleCellEdit} />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 180,
      renderCell: (params: any) => {
        const onClick = () => {
          Post("/api/v1/faqs/questions/reevaluate", {
            UserQuestionIds: [params.id],
          }).then((data) => {
            loadData()
          })
        }
        return <Button onClick={onClick}>Reevaluate</Button>
      },
    },
  ]

  const handleCellEdit = (
    id: number,
    status: number,
    correctQid: number,
    remark: string
  ) => {
    let newValues = updateQuestionDtos.filter(
      (x: UpdateUserQuestionCorrectQidDto) => x != null
    )

    const review = dto.questionsForReview.find((x: any) => x.id === id)

    const existing = newValues.find(
      (x: UpdateUserQuestionCorrectQidDto) => x.id === id
    )
    let needUpdate = false
    if (existing != null && existing !== undefined) {
      if (correctQid !== null && correctQid !== undefined) {
        if (correctQid !== review.correct_qid) {
          existing.correct_qid = +correctQid
          needUpdate = true
        }
      }
      if (status !== null && status !== undefined) {
        if (status !== review.status) {
          existing.status = status
          needUpdate = true
        }
      }
      if (remark !== null && remark !== undefined) {
        if (remark !== review.remark) {
          existing.remark = remark
          needUpdate = true
        }
      }
      if (needUpdate) {
        newValues = newValues.map((item: UpdateUserQuestionCorrectQidDto) => {
          if (item.id === existing.id) {
            return existing
          }
          return item
        })
      }
    } else {
      const newItem: UpdateUserQuestionCorrectQidDto = {
        id: +id,
        correct_qid: correctQid,
        status: status,
        remark: remark,
      }
      if (
        newItem.correct_qid > 0 ||
        newItem.status != null ||
        newItem.remark != null
      ) {
        newValues.push(newItem)
        needUpdate = true
      }
    }

    if (needUpdate) {
      setUpdateQuestionDtos(newValues)
    }
  }

  const loadTitle = (qid: string) => {
    Get<AskQuestionDto>(`/api/v1/faqs/question/${qid}`).then((data) => {
      if (data) {
        setGlobalTootip(data.title)
      }
    })
  }

  const onSelectionModelChange = (ids: GridSelectionModel) => {
    setSelectionModel(ids)
  }

  const changeMultipleData = (status: number, correct_qid: number) => {
    const newUpdateValue = updateQuestionDtos.filter(
      (x: UpdateUserQuestionCorrectQidDto) => x
    )

    selectionModel.map((item: number) => {
      const findItem = dto.questionsForReview.find(
        (x: UserQuestionDto) => x.id === item
      )

      const findIndexForUpdateQuestionDtos = newUpdateValue.findIndex(
        (x: UpdateUserQuestionCorrectQidDto) => x.id === item
      )
      if (findIndexForUpdateQuestionDtos > -1) {
        if (status !== -1)
          newUpdateValue[findIndexForUpdateQuestionDtos].status = status

        newUpdateValue[findIndexForUpdateQuestionDtos].correct_qid = correct_qid
      } else {
        if (
          (status !== findItem.status && status !== -1) ||
          correct_qid !== findItem.correct_qid
        )
          newUpdateValue.push({
            id: item,
            correct_qid:
              correct_qid !== findItem.correct_qid
                ? correct_qid
                : findItem.correct_qid,
            status:
              status !== findItem.status && status !== -1
                ? status
                : findItem.status,
            remark: findItem.remark,
          })
      }
    })

    setUpdateQuestionDtos(newUpdateValue)
    setSelectionModel([])
    setUpdateStatus(-1)
    setUpdateCorrectQid("")
  }

  const table = (
    <DataGrid
      sx={{
        fontSize: "14px",
      }}
      rows={dto.questionsForReview}
      columns={columns}
      pageSize={dto.pageSize}
      rowsPerPageOptions={[5, 10, 15, 20, 100]}
      checkboxSelection
      disableSelectionOnClick
      rowHeight={28}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      rowCount={dto.rowCount}
      pagination
      page={dto.page}
      loading={dto.loading}
      onPageChange={(value) => updateData("page", value)}
      onPageSizeChange={(value) => updateData("pageSize", value)}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={(model) => {
        setSortModel(model)
      }}
      sortingMode={"server"}
    />
  )

  return (
    <Box>
      <MDCardAction>
        <Box sx={{ display: "flex", flex: 1 }}>
          <TextField
            style={{ marginRight: "1rem" }}
            id="id-select"
            label="Id"
            variant="outlined"
            defaultValue={searchId}
            size="small"
            onChange={(e) => {
              setSearchId(e.target.value)
            }}
          />
          <TextField
            style={{ marginRight: "1rem" }}
            id="qid-select"
            label="CorrectQid"
            variant="outlined"
            defaultValue={searchCorrectQid}
            size="small"
            onChange={(e) => {
              setSearchCorrectQid(e.target.value)
            }}
          />
          <TextField
            style={{ marginRight: "1rem" }}
            id="askby-select"
            label="AskBy"
            variant="outlined"
            defaultValue={searchAskBy}
            size="small"
            onChange={(e) => {
              setSearchAskBy(e.target.value)
            }}
          />
          <Select
            id="status-select"
            autoWidth={true}
            defaultValue={0}
            onChange={(x) => setSelectedStatus(x.target.value as number)}
          >
            {userQuestionStatusDtos &&
              userQuestionStatusDtos.map((x: any, key: number) => (
                <MenuItem value={x.value} key={key}>
                  {x.text}
                </MenuItem>
              ))}
          </Select>

          <MDBox>
            <MDButton
              color="success"
              fullWidth={true}
              onClick={() => {
                if (dto.page === 0) {
                  onSearchClick()
                } else {
                  setDto({ ...dto, page: 0 })
                }
              }}
            >
              Search
            </MDButton>
          </MDBox>

          {selectedStatus ===
            userQuestionStatusDtos[userQuestionStatusDtos.length - 1]
              ?.value && (
            <MDBox>
              <Button
                onClick={() => {
                  setExtensionsGenerateOpen(true)
                }}
              >
                Generate
              </Button>
              <Dialog
                open={extensionsGenerateOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">
                  Question Extensions Generate
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please enter Correct_Qid, separated by commas, and enter the
                    maximum and minimum generation quantities.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Correct Qids"
                    fullWidth
                    type="text"
                    variant="outlined"
                    sx={{ marginTop: "1rem" }}
                    value={extensionsGenerateDto.correctQids}
                    onChange={(e) =>
                      updateExtensionsGenerateDto(
                        "correctQids",
                        e.target.value.replace(
                          /[^0-9,，,]|([,，]{2,})/g,
                          (match, p1) => {
                            return p1 ? "," : ""
                          }
                        )
                      )
                    }
                  />
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Min count"
                        fullWidth
                        type="text"
                        variant="outlined"
                        sx={{ marginTop: "1rem" }}
                        value={extensionsGenerateDto.minCount.toString()}
                        onChange={(e) =>
                          /^\d*$/.test(e.target.value) &&
                          updateExtensionsGenerateDto(
                            "minCount",
                            Number(e.target.value)
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Max Count"
                        fullWidth
                        type="text"
                        variant="outlined"
                        sx={{ marginTop: "1rem" }}
                        value={extensionsGenerateDto.maxCount.toString()}
                        onChange={(e) =>
                          /^\d*$/.test(e.target.value) &&
                          updateExtensionsGenerateDto(
                            "maxCount",
                            Number(e.target.value)
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCancelGenerate}>Cancel</Button>
                  <Button onClick={questionsExtensionsGenerate} autoFocus>
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </MDBox>
          )}
        </Box>

        {selectionModel.length > 0 && (
          <Button size="small" onClick={handleClickOpen}>
            open change
          </Button>
        )}
        <Dialog
          open={open}
          sx={{ "& .MuiDialog-paper": { width: 400, height: 400 } }}
          scroll={"paper"}
        >
          <DialogTitle>Change Question Status/CorrectQid</DialogTitle>
          <DialogContent>
            <DialogContentText>Change QuestionId</DialogContentText>
            <DialogContentText>
              {selectionModel.map((x: string) => {
                return x + " "
              })}
            </DialogContentText>
            <Box sx={{ flex: 1, marginTop: "0.5rem" }}>
              <Autocomplete
                openOnFocus
                disablePortal
                disableClearable
                options={questionStatus}
                size="small"
                sx={{ width: 150 }}
                getOptionLabel={(option) => option.value}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ padding: "0 !important" }}
                    type="button"
                    label="Change Status"
                  />
                )}
                onChange={(e, value) => {
                  setUpdateStatus(value.key)
                }}
              />
              <TextField
                autoFocus
                margin="dense"
                label="Correct Qid"
                fullWidth
                type="text"
                variant="outlined"
                sx={{ marginTop: "1rem" }}
                value={updateCorrectQid}
                onChange={(e) =>
                  setUpdateCorrectQid(e.target.value.replace(/[^\d]/g, ""))
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                handleClose(() => {
                  setUpdateStatus(-1)
                  setUpdateCorrectQid("")
                })
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                handleClose(
                  changeMultipleData(updateStatus, Number(updateCorrectQid))
                )
              }
            >
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </MDCardAction>
      <Divider />
      <MDBox sx={{ height: 590 }}>{table}</MDBox>
      <Divider />
    </Box>
  )
})
export default ReviewMain
