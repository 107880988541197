import palette from "./palette";
import boxShadow from "../functions/boxShadow";
import { BoxShadowsOption } from "../module";
const { common, tabs } = palette;

const boxShadows: BoxShadowsOption = {
  size: {
    xs: boxShadow([0, 2], [9, -5], common?.black, 0.15),
    sm: boxShadow([0, 5], [10, 0], common?.black, 0.12),
    md: `${boxShadow([0, 4], [6, -1], common?.black, 0.1)}, ${boxShadow(
      [0, 2],
      [4, -1],
      common?.black,
      0.06
    )}`,
    lg: `${boxShadow([0, 10], [15, -3], common?.black, 0.1)}, ${boxShadow(
      [0, 4],
      [6, -2],
      common?.black,
      0.05
    )}`,
    xl: `${boxShadow([0, 20], [25, -5], common?.black, 0.1)}, ${boxShadow(
      [0, 10],
      [10, -5],
      common?.black,
      0.04
    )}`,
    xxl: boxShadow([0, 20], [27, 0], common?.black, 0.05),
    none: "",
  },

  inset: boxShadow([0, 1], [2, 0], common?.black, 0.075, "inset"),
  colored: {
    primary: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.primary?.coloredShadows, //coloredShadows.primary,
      0.4
    )}`,
    secondary: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.secondary?.coloredShadows, //coloredShadows.secondary,
      0.4
    )}`,
    info: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.info?.coloredShadows, //coloredShadows.info,
      0.4
    )}`,
    success: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.success?.coloredShadows, // coloredShadows.success,
      0.4
    )}`,
    warning: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.warning?.coloredShadows, // coloredShadows.warning,
      0.4
    )}`,
    error: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.error?.coloredShadows, // coloredShadows.error,
      0.4
    )}`,
    light: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.light?.coloredShadows, //coloredShadows.light,
      0.4
    )}`,
    dark: `${boxShadow([0, 4], [20, 0], common?.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      palette.dark?.coloredShadows, // coloredShadows.dark,
      0.4
    )}`,
  },
  navbarBoxShadow: `${boxShadow(
    [0, 0],
    [1, 1],
    common?.white,
    0.9,
    "inset"
  )}, ${boxShadow([0, 20], [27, 0], common?.black, 0.05)}`,
  sliderBoxShadow: {
    thumb: boxShadow([0, 1], [13, 0], common?.black, 0.2),
  },
  tabsBoxShadow: {
    indicator: boxShadow([0, 1], [5, 1], tabs.indicator.boxShadow, 1),
  },
};

export default boxShadows;
