import { MDCard } from "../../components/MDCard"
import ReviewMain from "./components/review-main"
import ReviewFooter from "./components/review-footer"
import useAction from "./hook"
import { IconButton, Snackbar } from "@mui/material"
import { Fragment } from "react"
import CloseIcon from "@mui/icons-material/Close"

export default function ReviewPage() {
  const {
    dto,
    setDto,
    updateData,
    loadData,
    updateQuestionDtos,
    setUpdateQuestionDtos,
    sortModel,
    setSortModel,
    searchCorrectQid,
    setSearchCorrectQid,
    selectedStatus,
    setSelectedStatus,
    userQuestionStatusDtos,
    searchAskBy,
    setSearchAskBy,
    searchId,
    setSearchId,
    selectionModel,
    setSelectionModel,
    open,
    handleClickOpen,
    handleClose,
    extensionsGenerateOpen,
    setExtensionsGenerateOpen,
    questionsExtensionsGenerate,
    extensionsGenerateDto,
    updateExtensionsGenerateDto,
    onCancelGenerate,
    title,
    snackbarOpen,
    handleSnackbarClose,
  } = useAction()

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )

  return (
    <MDCard>
      <ReviewMain
        dto={dto}
        setDto={setDto}
        loadData={loadData}
        updateData={updateData}
        sortModel={sortModel}
        setSortModel={setSortModel}
        searchCorrectQid={searchCorrectQid}
        setSearchCorrectQid={setSearchCorrectQid}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        userQuestionStatusDtos={userQuestionStatusDtos}
        setUpdateQuestionDtos={setUpdateQuestionDtos}
        updateQuestionDtos={updateQuestionDtos}
        searchAskBy={searchAskBy}
        setSearchAskBy={setSearchAskBy}
        searchId={searchId}
        setSearchId={setSearchId}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        extensionsGenerateDto={extensionsGenerateDto}
        extensionsGenerateOpen={extensionsGenerateOpen}
        setExtensionsGenerateOpen={setExtensionsGenerateOpen}
        updateExtensionsGenerateDto={updateExtensionsGenerateDto}
        questionsExtensionsGenerate={questionsExtensionsGenerate}
        onCancelGenerate={onCancelGenerate}
      />
      <ReviewFooter
        updateQuestionDtos={updateQuestionDtos}
        setUpdateQuestionDtos={setUpdateQuestionDtos}
        loadData={loadData}
      />
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={title}
        action={action}
      />
    </MDCard>
  )
}
