import { Card } from "@mui/material";
import MDBox, { MDBoxProps } from "../MDBox";

export interface MDCardProps extends MDBoxProps {}
export function MDCard(props: MDCardProps) {
  const { padding, children, ...rest } = props;
  return (
    <Card sx={{ mt: 4 }}>
      <MDBox padding={padding ?? "1rem"} {...rest}>
        {children}
      </MDBox>
    </Card>
  );
}

export interface MDCardHeaderProps extends Partial<MDBoxProps> {}
export function MDCardHeader(props: MDCardHeaderProps) {
  const {
    variant,
    bgColor,
    borderRadius,
    coloredShadow,
    py,
    pr,
    color,
    mt,
    textAlign,
    children,
    ...rest
  } = props;
  return (
    <MDBox
      variant={variant ?? "gradient"}
      color={color ?? "white"}
      bgColor={bgColor ?? "secondary"}
      borderRadius={borderRadius ?? "lg"}
      coloredShadow={coloredShadow ?? "secondary"}
      py={py ?? 1}
      pr={pr ?? 0.5}
      mt={mt ?? -4}
      textAlign={textAlign ?? "center"}
      {...rest}
    >
      {children && children}
      {/* {chart && chart} */}
      {/* {children && (
        <MDTypography variant="h6" textTransform="capitalize">
          {props.children}
        </MDTypography>
      )} */}
    </MDBox>
  );
}

export interface MDCardBodyProps extends MDBoxProps {}
export function MDCardBody(props: MDCardBodyProps) {
  return (
    <MDBox pt={3} pb={1} px={1}>
      {props.children}
    </MDBox>
  );
}

export interface MDCardActionProps extends MDBoxProps {}
export function MDCardAction(props: MDCardActionProps) {
  return <MDBox display="flex">{props.children}</MDBox>;
}
