import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import { forwardRef } from "react";

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1500,
  },
});

const MDTooltip = forwardRef((props: TooltipProps, ref: any) => {
  return <NoMaxWidthTooltip {...props} ref={ref} />;
});

export default MDTooltip;
