import colors from "../../base/palette";
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";
import pxToRem from "../../functions/pxToRem";
import linearGradient from "../../functions/linearGradient";

const { common, grey, transparent, dark, info } = colors;
const { borderWidth } = borders;
const {
  size: { md },
} = boxShadows;

const switchButton = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    switchBase: {
      color: dark.gradient?.main,

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-checked": {
        color: dark.gradient?.main,

        "&:hover": {
          backgroundColor: transparent.main,
        },

        "& .MuiSwitch-thumb": {
          borderColor: `${dark.gradient?.main} !important`,
        },

        "& + .MuiSwitch-track": {
          backgroundColor: `${dark.gradient?.main} !important`,
          borderColor: `${dark.gradient?.main} !important`,
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(
          info?.gradient?.main,
          info?.gradient?.state
        ),
      },
    },

    thumb: {
      backgroundColor: common?.white,
      boxShadow: md,
      border: `${borderWidth[1]} solid ${grey?.[400]}`,
    },

    track: {
      width: pxToRem(32),
      height: pxToRem(15),
      backgroundColor: grey?.[400],
      border: `${borderWidth[1]} solid ${grey?.[400]}`,
      opacity: 1,
    },

    checked: {},
  },
};

export default switchButton;
