import { Navigate } from "react-router-dom"
import settings from "../appsettings"
import { ResponseCode } from "../dtos/predict-response"

export interface SmartFaqResponse<T> {
  code: number
  msg: string
  data: T
}

export async function Post<T>(url: string, data?: object) {
  return await base<T>(url, "post", data)
}

export async function Get<T>(url: string) {
  return await base<T>(url, "get")
}

export async function base<T>(
  url: string,
  method: "post" | "get",
  data?: object
) {
  return await fetch(`${(window as any).appsettings.serverUrl}${url}`, {
    method: method,
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((res) => res.json())
    .then((res: SmartFaqResponse<T>) => {
      if (res.code === ResponseCode.ok) {
        return res.data
      } else {
        throw new Error(res.msg)
      }
    })
}
