import {
  Divider,
  Fade,
  Icon,
  IconButton,
  Snackbar,
  SnackbarProps,
  styled,
} from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";
import { useMaterialUIController } from "../../hooks/useMaterialUIController";

export interface MDSnackbarRootProps extends SnackbarProps {
  color: PalettePrimordialColor;
  bgWhite?: boolean;
}

export interface MDSnackbarProps extends MDSnackbarRootProps {
  icon: React.ReactNode;
  title: string;
  dateTime?: string;
  detailContent: React.ReactNode;
  close?(): void;
}

const MDSnackbarIconRoot = styled(Icon)<{ ownerState: MDSnackbarRootProps }>(
  ({ theme, ownerState }) => {
    const { palette, functions, typography } = theme;
    const { color, bgWhite } = ownerState;

    const { transparent } = palette;
    const { pxToRem, linearGradient } = functions;
    const { size } = typography;

    // backgroundImage value
    let backgroundImageValue;

    if (bgWhite) {
      backgroundImageValue =
        palette[color].gradient !== undefined
          ? linearGradient(
              palette[color].gradient!.main,
              palette[color].gradient!.state
            )
          : linearGradient(
              palette.info.gradient?.main,
              palette.info.gradient?.state
            );
    } else if (color === "light") {
      backgroundImageValue = linearGradient(
        palette.dark.gradient?.main,
        palette.dark.gradient?.state
      );
    }

    return {
      backgroundImage: backgroundImageValue,
      WebkitTextFillColor:
        bgWhite || color === "light" ? transparent.main : palette.common.white,
      WebkitBackgroundClip: "text",
      marginRight: pxToRem(8),
      fontSize: size.lg,
      transform: `translateY(${pxToRem(-2)})`,
    };
  }
);

export default function MDSnackbar(props: MDSnackbarProps) {
  const {
    color,
    icon,
    title,
    dateTime,
    detailContent,
    close,
    bgWhite,
    ...rest
  } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let titleColor: PalettePrimordialColor | "inherit" | "dark" | "white";
  let dateTimeColor:
    | PalettePrimordialColor
    | "inherit"
    | "dark"
    | "white"
    | "text";
  let dividerColor: boolean;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = darkMode ? "inherit" : "dark";
    dateTimeColor = darkMode ? "inherit" : "text";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...rest}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={close}
        >
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <MDBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={{ backgroundColor: "white" }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDSnackbarIconRoot
              fontSize="small"
              ownerState={{ color, bgWhite }}
            >
              {icon}
            </MDSnackbarIconRoot>
            <MDTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            {dateTime && (
              <MDTypography variant="caption" color={dateTimeColor}>
                {dateTime}
              </MDTypography>
            )}
            {close && (
              <Icon
                sx={{
                  color: ({
                    palette: {
                      dark,
                      common: { white },
                    },
                  }) =>
                    (bgWhite && !darkMode) || color === "light"
                      ? dark.main
                      : white,
                  fontWeight: ({ typography: { fontWeightBold } }) =>
                    fontWeightBold,
                  cursor: "pointer",
                  marginLeft: 2,
                  transform: "translateY(-1px)",
                }}
                onClick={close}
              >
                close
              </Icon>
            )}
          </MDBox>
        </MDBox>
        <Divider sx={{ margin: 0 }} light={dividerColor} />
        <MDBox
          p={1.5}
          sx={{
            fontSize: ({ typography: { size } }) => size.sm,
            color: ({
              palette: {
                common: { white },
                text,
              },
            }) => {
              let colorValue =
                bgWhite || color === "light" ? text.primary : white;

              if (darkMode) {
                colorValue = color === "light" ? "inherit" : white;
              }

              return colorValue;
            },
          }}
        >
          {detailContent}
        </MDBox>
      </MDBox>
    </Snackbar>
  );
}
