import React, { forwardRef } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TypographyProps } from "@mui/material";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";

export interface MDTypographyProps extends TypographyProps {
  color?: PalettePrimordialColor | "inherit" | "text" | "white";
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  verticalAlign?:
    | "unset"
    | "baseline"
    | "sub"
    | "super"
    | "text-top"
    | "text-bottom"
    | "middle"
    | "top"
    | "bottom";
  fontWeight?: "light" | "regular" | "medium" | "bold";
  opacity?: number;
  textGradient?: boolean;
  darkMode?: boolean;
  component?: string | any;
  href?: string;
  fullWidth?: boolean;
}

const MDTypographyRoot = styled(Typography)<{ ownerState: MDTypographyProps }>(
  ({ theme, ownerState }) => {
    const { palette, typography, functions } = theme;
    const {
      color,
      textTransform,
      verticalAlign,
      fontWeight,
      opacity,
      textGradient,
      darkMode,
    } = ownerState;
    const { transparent, common } = palette;
    const {
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
    } = typography;
    const { linearGradient } = functions;
    const fontWeights = {
      light: fontWeightLight,
      regular: fontWeightRegular,
      medium: fontWeightMedium,
      bold: fontWeightBold,
    };

    const gradientStyles = (): {} => {
      let bgImage;
      if (
        color !== "inherit" &&
        color !== "text" &&
        color !== "white" &&
        color !== undefined
      ) {
        let gradient = palette[color].gradient;
        if (gradient !== undefined) {
          bgImage = linearGradient(gradient.main, gradient.state);
        } else {
          bgImage = linearGradient(
            palette.dark.gradient?.main,
            palette.dark.gradient?.state
          );
        }
      } else {
        bgImage = linearGradient(
          palette.dark.gradient?.main,
          palette.dark.gradient?.state
        );
      }

      return {
        backgroundImage: bgImage,
        display: "inline-block",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: transparent.main,
        position: "relative",
        zIndex: 1,
      };
    };

    let colorValue =
      color === undefined || color === "inherit"
        ? "inherit"
        : color === "white"
        ? palette.common.white
        : color === "text"
        ? palette.text.primary
        : palette[color].main;

    if (darkMode && color === "inherit") {
      colorValue = "inherit";
    } else if (darkMode && color === "dark") colorValue = common.white;

    return {
      opacity,
      textTransform: textTransform ?? "none",
      verticalAlign: verticalAlign ?? "unset",
      textDecoration: "none",
      color: colorValue,
      fontWeight: fontWeight && fontWeights[fontWeight],
      ...(textGradient && gradientStyles()),
    };
  }
);

const MDTypography = forwardRef(
  (props: MDTypographyProps, ref: React.ForwardedRef<HTMLSpanElement>) => {
    const {
      children,
      color,
      textTransform,
      verticalAlign,
      fontWeight,
      opacity,
      textGradient,
      darkMode,
      ...rest
    } = props;
    return (
      <MDTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </MDTypographyRoot>
    );
  }
);

export default MDTypography;
