import { MenuItem, Select } from "@mui/material";
import { EnumSelectProps } from "./props";

export const EnumSelectCom = (props: EnumSelectProps) => {
  const { value, options, onChange } = props;
  return (
    <Select
      style={{
        height: "44px",
        minWidth: "100px",
      }}
      name="enum-select"
      autoWidth={true}
      value={value ?? -1}
      onChange={(x) => {
        onChange(Number(x.target.value));
      }}
    >
      {options.map((x: { key: number; value: string }, index: number) => (
        <MenuItem value={x.key} key={index}>
          {x.value}
        </MenuItem>
      ))}
    </Select>
  );
};
