import { MenuItem, Select } from "@mui/material";
import { SelectProps } from "./props";

export const SelectComponent = (props: SelectProps) => {
  const { k, id, value, option, onChange } = props;

  return (
    <Select
      name={`select-${id}`}
      onChange={(e) => {
        onChange(id, k, e.target.value);
      }}
      value={value as number}
    >
      {option.map((item: { key: number; value: string }, key: number) => (
        <MenuItem key={key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  );
};
