import { ListItemText, styled } from "@mui/material";

export interface SideItemTextProps {
  miniSidenav?: boolean;
  transparentSidenav?: boolean;
  whiteSidenav?: boolean;
  active: boolean;
}

export default styled(ListItemText)<{ ownerState: SideItemTextProps }>(
  ({ theme, ownerState }) => {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, transparentSidenav, active } = ownerState;
    const { fontWeightRegular, fontWeightLight } = typography;
    const { pxToRem } = functions;
    return {
      marginLeft: pxToRem(10),
      [breakpoints.up("xl")]: {
        opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
        maxWidth:
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
        marginLeft:
          miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },

      "& span": {
        fontWeight: active ? fontWeightRegular : fontWeightLight,
        fontSize: typography.size.sm,
        lineHeight: 0,
      },
    };
  }
);
