import enumArray from "enum-array";
import { useAction } from "./hook";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import MDBox from "../../components/MDBox";
import { MDCard, MDCardAction } from "../../components/MDCard";
import MDButton from "../../components/MDButton";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import {
  SampleStatusType,
  SampleType,
  StatusType,
  UserQuestionLImRequestDto,
  sampleTypeTitle,
} from "../../dtos/gpt-train-response";
import { SelectComponent } from "./component/select";
import { InputComponent } from "./component/input";
import React from "react";
import MDTooltip from "../../components/MDTooltip";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import { clone } from "ramda";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { EnumSelectCom } from "./component/enum-select";
import BackspaceSharpIcon from "@mui/icons-material/BackspaceSharp";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LlmPage() {
  const {
    dto,
    promptDialog,
    sortModel,
    searchDto,
    updateLlmData,
    addConversationDto,
    editConversationDto,
    deleteConversationDto,
    deleteConversationFn,
    conversationGroupDto,
    updateData,
    setSortModel,
    setMessage,
    changeConversationSampleType,
    updatePromptDialog,
    updateListValue,
    addConversation,
    editConversationOpen,
    loadData,
    updateSearchDto,
    onSaveClick,
    addConversationAdd,
    addConversationUpdate,
    addConversationRemove,
    addConversationSave,
    editConversationAdd,
    editConversationUpdate,
    editConversationRemove,
    editConversationSave,
    updateAddConversationDto,
    updateEditConversationDto,
    updateConversationGroupDto,
    updateDeleteConversationDto,
    editConversationUpdateSampleStatus,
  } = useAction();

  const maxLengthInQuestions = Math.max(
    ...dto.userQuestionLlmRequests.map(
      (el: UserQuestionLImRequestDto) => el.question.length
    )
  );
  const minWidthForQuestion = 380;
  const maxWidthForQuestion = 484;
  const questionActualWidth = maxLengthInQuestions * 14 + 14;

  let columns = [
    { field: "id", headerName: "ID", width: 80 },
    // {
    //   field: "prompts",
    //   headerName: "Prompt",
    //   width: 80,
    //   renderCell: (params: GridCellParams) => {
    //     return (
    //       <div
    //         style={{ cursor: "pointer", width: "100%" }}
    //         onClick={() =>
    //           // !promptDialog.status &&
    //           // setPromptDialog({
    //           //   status: true,
    //           //   data: params.row?.prompts ?? [],
    //           //   id: params.row?.id ?? null,
    //           // })
    //           {
    //             if (!promptDialog.status) {
    //               updatePromptDialog("status", true);
    //               updatePromptDialog("data", params.row?.prompts ?? []);
    //               updatePromptDialog("id", params.row?.id ?? null);
    //             }
    //           }
    //         }
    //       >
    //         Open
    //       </div>
    //     );
    //   },
    // },
    {
      field: "question",
      headerName: "Question",
      width:
        questionActualWidth > minWidthForQuestion
          ? questionActualWidth < maxWidthForQuestion
            ? questionActualWidth
            : maxWidthForQuestion
          : minWidthForQuestion,
      renderCell: (params: GridCellParams) => {
        return (
          <MDTooltip title={params.formattedValue} placement="bottom-start">
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params.formattedValue}
            </span>
          </MDTooltip>
        );
      },
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      renderCell: (params: GridCellParams) => {
        return params.row?.model ?? "";
      },
    },
    { field: "askBy", headerName: "Askby", width: 100 },
    {
      field: "createdDate",
      headerName: "Date",
      width: 160,
      renderCell: (params: GridCellParams) => {
        return (
          <span>
            {new Date(params.row.createdDate * 1000).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <SelectComponent
            value={params.row.status}
            id={params.row.id}
            k="status"
            option={enumArray.getEnumArray(StatusType)}
            onChange={updateListValue}
          />
        );
      },
    },
    {
      field: "groupCode",
      headerName: "Group Code",
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <InputComponent
            value={
              params.row.groupCode
                ? (params.row.groupCode as number).toString()
                : ""
            }
            id={params.row.id}
            k="groupCode"
            onChange={updateListValue}
          />
        );
      },
    },
    {
      field: "sampleType",
      headerName: "Sample Type",
      width: 150,
      renderCell: (params: GridCellParams) => {
        return sampleTypeTitle[params.row?.sampleType as SampleType] ?? "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (
                !conversationGroupDto.open &&
                params.row.groupCode &&
                !updateLlmData.length
              ) {
                updateConversationGroupDto("open", true);
                updateConversationGroupDto("groupCode", params.row.groupCode);
              } else if (updateLlmData.length > 0) {
                setMessage("warning", "info", "", "请保存当次修改再查看");
              } else if (!params.row.groupCode) {
                setMessage("warning", "info", "", "当前item没有对话组数据");
              }
            }}
          >
            Open Conversation Group
          </div>
        );
      },
    },
  ];

  const table = (
    <DataGrid
      sx={{
        fontSize: "14px",
      }}
      rows={dto.userQuestionLlmRequests}
      columns={columns}
      pageSize={dto.pageSize}
      rowsPerPageOptions={[10, 15, 20, 50, 100]}
      // checkboxSelection
      disableSelectionOnClick
      rowHeight={28}
      rowCount={dto.rowCount}
      pagination
      page={dto.page}
      loading={dto.loading}
      onPageChange={(value) => updateData("page", value)}
      onPageSizeChange={(value) => updateData("pageSize", value)}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      sortingMode={"server"}
    />
  );

  return (
    <MDCard>
      <Box sx={{ display: "flex", flex: 1, height: "35px" }}>
        <TextField
          style={{ marginRight: "1rem" }}
          id="groupCode-select"
          name="groupCode-select-main"
          label="groupCode"
          variant="outlined"
          value={searchDto.groupCode ?? ""}
          size="small"
          onChange={(e) => {
            const groupCode = !!e.target.value.replace(/[^0-9]/g, "")
              ? Number(e.target.value.replace(/[^0-9]/g, ""))
              : null;

            updateSearchDto("groupCode", groupCode);
          }}
        />
        <Select
          id="status-select"
          name="status-select-main"
          autoWidth={true}
          defaultValue={10}
          style={{ width: "76px" }}
          onChange={(x) => updateSearchDto("status", x.target.value as number)}
        >
          {enumArray.getEnumArray(StatusType).map((x: any, key: number) => (
            <MenuItem value={x.key} key={key}>
              {x.value}
            </MenuItem>
          ))}
        </Select>
        <MDBox>
          <MDButton
            color="success"
            fullWidth={true}
            onClick={() => loadData(0, dto.pageSize, sortModel)}
          >
            Search
          </MDButton>
        </MDBox>
      </Box>

      <Divider />

      <MDBox sx={{ height: 590 }}>{table}</MDBox>
      <Divider />
      <MDCardAction>
        <MDBox>
          <MDButton
            variant={updateLlmData.length ? "gradient" : "text"}
            color="success"
            disabled={!updateLlmData.length}
            fullWidth={true}
            onClick={onSaveClick}
          >
            Save
          </MDButton>
        </MDBox>
      </MDCardAction>

      <Dialog
        open={promptDialog.status}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ userSelect: "none" }}>
          {promptDialog.id && `ID: ${promptDialog.id},`} Prompt List
        </DialogTitle>
        <DialogContent dividers={true}>
          {promptDialog.data && (
            <div style={{ opacity: 1, minWidth: "300px", userSelect: "none" }}>
              {promptDialog.data.map((item, index) => (
                <React.Fragment key={index}>
                  {!!item && (
                    <>
                      <p
                        style={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                      >
                        {item}
                      </p>
                      {index !== (promptDialog.data?.length ?? 0) - 1 && (
                        <Divider />
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              promptDialog.status && updatePromptDialog("status", false)
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={conversationGroupDto.open}
        fullScreen
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ display: "flex", flexWrap: "wrap" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                updateConversationGroupDto("open", false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ userSelect: "none" }}
            >
              Conversation Group
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                addConversation();
              }}
            >
              Add Conversation
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent
          dividers={true}
          style={{
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {conversationGroupDto.loading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Sample Type：
                <Select
                  id="sample-type-select"
                  name="sample-type-select-item"
                  autoWidth={true}
                  value={conversationGroupDto.modifieSampleType ?? -1}
                  style={{ minWidth: "100px", height: "100%" }}
                  onChange={(x) => {
                    updateConversationGroupDto(
                      "modifieSampleType",
                      x.target.value === -1 ? null : (x.target.value as number)
                    );
                  }}
                >
                  {[
                    {
                      key: -1,
                      value: "未标记",
                    },
                    ...enumArray.getEnumArray(SampleType),
                  ].map((x: any, key: number) => (
                    <MenuItem
                      value={x.key}
                      key={key}
                      style={{ minWidth: "100px" }}
                    >
                      {x.value}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => {
                    changeConversationSampleType();
                  }}
                >
                  Save
                </Button>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "calc(100% - 35px)",
                  display: "flex",
                }}
              >
                {conversationGroupDto.conversations.map((item, index) => (
                  <div
                    style={{
                      position: "relative",
                      width: "500px",
                      height: "100%",
                      marginRight:
                        index !== conversationGroupDto.conversations.length - 1
                          ? "10px"
                          : 0,
                    }}
                    key={index}
                  >
                    {!item.isOriginal && (
                      <div
                        style={{
                          position: "absolute",
                          top: -8,
                          right: -8,
                          zIndex: 10,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          updateDeleteConversationDto(
                            "conversationId",
                            item.conversationId
                          );

                          updateDeleteConversationDto("open", true);
                        }}
                      >
                        <BackspaceSharpIcon fontSize="small" />
                      </div>
                    )}

                    <div
                      className={`${!item.isShow && "hide-scrollbar"}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        flexShrink: 0,
                        borderRadius: "8px",
                        border:
                          deleteConversationDto.conversationId ===
                          item.conversationId
                            ? "1px solid #f87171"
                            : "1px solid #d1d5db",
                        userSelect: "none",
                        overflowY: "auto",
                        padding: "10px",
                        cursor: item.isOriginal ? "auto" : "pointer",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => {
                        !item.isOriginal &&
                          editConversationOpen(item.conversationId);
                      }}
                      onScroll={() => {
                        if (item.timeOut) {
                          clearInterval(item.timeOut);
                        }
                        const newData = clone(conversationGroupDto);

                        newData.conversations[index] = {
                          ...newData.conversations[index],
                          isShow: true,
                          timeOut: setTimeout(() => {
                            newData.conversations[index] = {
                              ...newData.conversations[index],
                              isShow: false,
                              timeOut: null,
                            };
                            updateConversationGroupDto(
                              "conversations",
                              newData.conversations
                            );
                          }, 3000),
                        };

                        updateConversationGroupDto(
                          "conversations",
                          newData.conversations
                        );
                      }}
                    >
                      {item.userQuestions.map(
                        (userQuestionItem, userQuestionIndex) => (
                          <div
                            key={userQuestionIndex}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "10px",
                              flexShrink: 0,
                              marginTop: userQuestionIndex > 0 ? "5px" : 0,
                              marginBottom:
                                index < item.userQuestions.length - 1
                                  ? "5px"
                                  : 0,
                              backgroundColor: "#e2e8f0",
                              borderRadius: "8px",
                            }}
                          >
                            <div>Q：{userQuestionItem.question}</div>
                            <div>A：{userQuestionItem.answer}</div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateConversationGroupDto("open", false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addConversationDto.open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"xl"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ display: "flex", flexWrap: "wrap" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                updateAddConversationDto("open", false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ userSelect: "none" }}
            >
              Add conversation
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                addConversationAdd();
              }}
            >
              <AddIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent
          dividers={true}
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "49%",
              height: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <span
                style={{
                  userSelect: "none",
                }}
              >
                Original Sample Status：
              </span>
              <EnumSelectCom
                value={
                  (addConversationDto.orignalConversation
                    ?.sampleStatus as number) ?? null
                }
                onChange={(value) => {
                  const newData = clone(addConversationDto);

                  if (newData.orignalConversation) {
                    newData.orignalConversation.sampleStatus =
                      Number(value) === -1 || value === null
                        ? null
                        : (Number(value) as SampleStatusType);

                    updateAddConversationDto(
                      "orignalConversation",
                      newData.orignalConversation
                    );
                  }
                }}
                options={[
                  ...enumArray.getEnumArray(SampleStatusType),
                  {
                    key: -1,
                    value: "未选择",
                  },
                ]}
              />
            </div>
            <div
              style={{
                flex: 1,
                backgroundColor: "white",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                userSelect: "none",
                borderRadius: "8px",
                border: "1px solid #d1d5db",
              }}
              className={`${
                !addConversationDto.orignalIsShow && "hide-scrollbar"
              }`}
              onScroll={() => {
                if (addConversationDto.orignalTimeOut) {
                  clearInterval(addConversationDto.orignalTimeOut);
                }

                updateAddConversationDto("orignalIsShow", true);
                updateAddConversationDto(
                  "orignalTimeOut",
                  setTimeout(() => {
                    updateAddConversationDto("orignalIsShow", false);
                    updateAddConversationDto("orignalTimeOut", null);
                  }, 3000)
                );
              }}
            >
              {addConversationDto.orignalConversation &&
                addConversationDto.orignalConversation.userQuestions.map(
                  (item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        flexShrink: 0,
                        marginTop: index > 0 ? "5px" : 0,
                        marginBottom:
                          index <
                          addConversationDto.orignalConversation!.userQuestions
                            .length -
                            1
                            ? "5px"
                            : 0,
                        backgroundColor: "#e2e8f0",
                        borderRadius: "8px",
                      }}
                    >
                      <div>Q：{item.question}</div>
                      <div>A：{item.answer}</div>
                    </div>
                  )
                )}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <span
                style={{
                  userSelect: "none",
                }}
              >
                Add Sample Status：
              </span>

              <EnumSelectCom
                value={(addConversationDto.addSampleStatus as number) ?? null}
                onChange={(value) => {
                  const newData = clone(addConversationDto);

                  if (newData.orignalConversation) {
                    newData.addSampleStatus =
                      Number(value) === -1 || value === null
                        ? null
                        : (Number(value) as SampleStatusType);

                    updateAddConversationDto(
                      "addSampleStatus",
                      newData.addSampleStatus
                    );
                  }
                }}
                options={[
                  ...enumArray.getEnumArray(SampleStatusType),
                  {
                    key: -1,
                    value: "未选择",
                  },
                ]}
              />
            </div>
            <div
              style={{
                flex: 1,
                backgroundColor: "#64748b",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                borderRadius: "8px",
              }}
              className={`${!addConversationDto.addIsShow && "hide-scrollbar"}`}
              onScroll={() => {
                if (addConversationDto.addTimeOut) {
                  clearInterval(addConversationDto.addTimeOut);
                }

                updateAddConversationDto("addIsShow", true);
                updateAddConversationDto(
                  "addTimeOut",
                  setTimeout(() => {
                    updateAddConversationDto("addIsShow", false);
                    updateAddConversationDto("addTimeOut", null);
                  }, 3000)
                );
              }}
            >
              {addConversationDto.addConversation &&
                addConversationDto.addConversation.userQuestions.map(
                  (item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        flexShrink: 0,
                        marginTop: index > 0 ? "5px" : 0,
                        marginBottom:
                          index <
                          addConversationDto.addConversation!.userQuestions
                            .length -
                            1
                            ? "5px"
                            : 0,
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginBottom: "5px",
                          }}
                        >
                          <span
                            style={{
                              height: "44px",
                              lineHeight: "44px",
                              userSelect: "none",
                            }}
                          >
                            Q：
                          </span>
                          <TextField
                            label=""
                            multiline
                            minRows={1}
                            value={item.question}
                            style={{
                              width: "100%",
                            }}
                            onChange={(e) => {
                              addConversationUpdate(
                                index,
                                "question",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              height: "44px",
                              lineHeight: "44px",
                              userSelect: "none",
                            }}
                          >
                            A：
                          </span>
                          <TextField
                            label=""
                            multiline
                            minRows={1}
                            value={item.answer}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              addConversationUpdate(
                                index,
                                "answer",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          height: "44px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          onClick={() => {
                            addConversationRemove(index);
                          }}
                        >
                          <RemoveCircleOutlineIcon
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateAddConversationDto("open", false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              addConversationSave();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editConversationDto.open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar style={{ display: "flex", flexWrap: "wrap" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                updateEditConversationDto("open", false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ userSelect: "none" }}
            >
              Edit conversation
            </Typography>
            {/* 暂时隐藏 编辑新增 */}
            {/* <Button
              autoFocus
              color="inherit"
              onClick={() => {
                editConversationAdd();
              }}
            >
              <AddIcon />
            </Button> */}
          </Toolbar>
        </AppBar>
        <DialogContent
          dividers={true}
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              padding: "0 10px",
            }}
            className={`${!editConversationDto.isShow && "hide-scrollbar"}`}
            onScroll={() => {
              if (editConversationDto.timeOut) {
                clearInterval(editConversationDto.timeOut);
              }

              updateEditConversationDto("isShow", true);
              updateEditConversationDto(
                "timeOut",
                setTimeout(() => {
                  updateEditConversationDto("isShow", false);
                  updateEditConversationDto("timeOut", null);
                }, 3000)
              );
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <span
                style={{
                  userSelect: "none",
                }}
              >
                Sample Status：
              </span>

              <EnumSelectCom
                value={
                  (editConversationDto.conversation?.sampleStatus as number) ??
                  null
                }
                onChange={(value) => {
                  editConversationUpdateSampleStatus(
                    Number(value) === -1 || value === null
                      ? null
                      : (Number(value) as SampleStatusType)
                  );
                }}
                options={[
                  ...enumArray.getEnumArray(SampleStatusType),
                  {
                    key: -1,
                    value: "未选择",
                  },
                ]}
              />
            </div>

            {editConversationDto.conversation &&
              editConversationDto.conversation.userQuestions.map(
                (item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "5px 10px",
                      flexShrink: 0,
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            height: "44px",
                            lineHeight: "44px",
                            userSelect: "none",
                          }}
                        >
                          Q：
                        </span>
                        <TextField
                          label=""
                          multiline
                          minRows={1}
                          value={item.question}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e) => {
                            editConversationUpdate(
                              index,
                              "question",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            height: "44px",
                            lineHeight: "44px",
                            userSelect: "none",
                          }}
                        >
                          A：
                        </span>
                        <TextField
                          label=""
                          multiline
                          minRows={1}
                          value={item.answer}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            editConversationUpdate(
                              index,
                              "answer",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* 暂时隐藏 编辑删除 */}
                    {/* <div
                      style={{
                        display: "flex",
                        height: "44px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          editConversationRemove(index);
                        }}
                      >
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                )
              )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateEditConversationDto("open", false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              editConversationSave();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConversationDto.open}
        scroll={"paper"}
        maxWidth={"xs"}
      >
        <DialogContent dividers={true} style={{}}>
          确认是否删除这个对话
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              updateDeleteConversationDto("open", false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              deleteConversationDto.conversationId &&
                deleteConversationFn(deleteConversationDto.conversationId);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MDCard>
  );
}
