import colors from "../../base/palette";
import boxShadows from "../../base/boxShadows";
import borders from "../../base/borders";

const { common } = colors;
const {
  size: { md },
} = boxShadows;
const { borderRadius } = borders;

const tableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: common?.white,
      boxShadow: md,
      borderRadius: borderRadius.xl,
    },
  },
};
export default tableContainer;
