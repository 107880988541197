export interface GetUserQuestionsResponse {
  code: number;
  msg: string;
  data: UserQuestionDto[];
}

export interface UserQuestionDto {
  id: number;
  createdAt: Date;
  question: string;
  rasaPredictedQid: number;
  anyqPredictedQid: number;
  correct_qid: number;
  status: number;
  remark: string;
  model3Confidence: string;
  askBy: string;
  answers: QuestionAnswerDto[];
  modelVPredictedQid: number;
}

export type SortColumnsType = Record<keyof UserQuestionDto, string>;
export interface ReviewDto {
  questionsForReview: UserQuestionDto[];
  sortColumns?: SortColumnsType;
  rowCount: number;
}

export interface UserQuestionStatusDto {
  value: number;
  text: string;
}

export interface QuestionAnswerDto {
  id: number;
  userQuestionId: number;
  answer: string;
  createdDate: string;
  answerFrom: AnswerFrom;
  answerType: AnswerType;
}

export enum AnswerFrom {
  Hi200,
  Metis,
}

export enum AnswerType {
  Text = 10,
  Image = 20,
  Voice = 30,
  Video = 40,
  File = 50,
}

export const answerTypeTitle = {
  [AnswerType.Text]: "文本",
  [AnswerType.Image]: "图片",
  [AnswerType.Voice]: "音频",
  [AnswerType.Video]: "视频",
  [AnswerType.File]: "文件",
};

export const answerFromType = {
  [AnswerFrom.Hi200]: "Hi200",
  [AnswerFrom.Metis]: "Metis",
};

export interface QuestionsBehaviourType {
  value: number;
  text: string;
  description: string;
}

export interface QuestionsBehaviourTypeDto {
  value: number;
  count: number;
  text: string;
  description: string;
}
