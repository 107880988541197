import pxToRem from "../functions/pxToRem";
import colors from "../base/palette";
import boxShadows from "../base/boxShadows";
import borders from "../base/borders";

const { transparent } = colors;
const {
  size: { lg },
} = boxShadows;
const { borderRadius } = borders;

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      padding: pxToRem(8),
      borderRadius: borderRadius.md,
    },
  },
};
export default popover;
