import colors from "../../base/palette";
import pxToRem from "../../functions/pxToRem";
import boxShadow from "../../functions/boxShadow";

const stepIcon = {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: colors.common?.white,
        fill: colors.common?.white,
        stroke: colors.common?.white,
        borderColor: colors.common?.white,
        boxShadow: boxShadow([0, 0], [0, 2], colors.common?.white, 1),
      },

      "&.Mui-completed": {
        background: colors.common?.white,
        fill: colors.common?.white,
        stroke: colors.common?.white,
        borderColor: colors.common?.white,
        boxShadow: boxShadow([0, 0], [0, 2], colors.common?.white, 1),
      },
    },
  },
};
export default stepIcon;
