import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Drawer, Icon, List, Menu, styled } from "@mui/material";
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "../../hooks/useMaterialUIController";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { IRouteItem } from "../../routes";
import NavItem from "./components/NavItem";

export interface MDSideBarProps {
  logoIcon: string;
  accountName: string;
  routes: IRouteItem[];
  onMouseEnter(): void;
  onMouseLeave(): void;
}

export interface MDSideBarRootProps {
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  miniSidenav: boolean;
  darkMode: boolean;
}

export interface MDSideBarLogoProps {
  logoIcon: string;
  brandName: string;
  textColor: "inherit" | "dark" | "white";
  miniSidenav: boolean;
  onClose(): void;
}

export interface MDSideBarNavProp {
  name: string;
  icon: string;
  routes: ReadonlyArray<IRouteItem>;
  light: boolean;
  textColor: "inherit" | "dark" | "white";
}

const SideNavBarRoot = styled(Drawer)<{ ownerState: MDSideBarRootProps }>(
  ({ theme, ownerState }) => {
    const { palette, boxShadows, transitions, breakpoints, functions } = theme;
    const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } =
      ownerState;
    const sidebarWidth = 250;
    const { transparent, sideBackground } = palette;
    const { pxToRem, linearGradient } = functions;

    let backgroundValue = darkMode
      ? sideBackground
      : linearGradient(
          palette.dark.gradient?.main,
          palette.dark.gradient?.state
        );

    if (transparentSidenav) {
      backgroundValue = transparent.main;
    } else if (whiteSidenav) {
      backgroundValue = palette.common.white;
    }

    // styles for the sidenav when miniSidenav={false}
    const drawerOpenStyles = () => ({
      background: backgroundValue,
      transform: "translateX(0)",
      transition: transitions.create("transform", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),

      [breakpoints.up("xl")]: {
        boxShadow: transparentSidenav ? "none" : boxShadows.size.xxl,
        marginBottom: transparentSidenav ? 0 : "inherit",
        left: "0",
        width: sidebarWidth,
        transform: "translateX(0)",
        transition: transitions.create(["width", "background-color"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen,
        }),
      },
    });

    // styles for the sidenav when miniSidenav={true}
    const drawerCloseStyles = () => ({
      background: backgroundValue,
      transform: `translateX(${pxToRem(-320)})`,
      transition: transitions.create("transform", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),

      [breakpoints.up("xl")]: {
        boxShadow: transparentSidenav ? "none" : boxShadows.size.xxl,
        marginBottom: transparentSidenav ? 0 : "inherit",
        left: "0",
        width: pxToRem(96),
        overflowX: "hidden",
        transform: "translateX(0)",
        transition: transitions.create(["width", "background-color"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.shorter,
        }),
      },
    });

    return {
      "& .MuiDrawer-paper": {
        boxShadow: boxShadows.size.xxl,
        border: "none",

        ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
      },
    };
  }
);

export default function SideNavBar(props: MDSideBarProps) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const { accountName, routes, onMouseEnter, onMouseLeave } = props;
  const location = useLocation();
  let textColor: "white" | "dark" | "inherit" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const renderAccount = (
    <MDBox pt={3} pb={1} px={4} textAlign="center">
      <MDBox
        display={{ xs: "block", xl: "none" }}
        position="absolute"
        top={0}
        right={0}
        p={1.625}
        onClick={() => setMiniSidenav(dispatch, true)}
        sx={{ cursor: "pointer" }}
      >
        <MDTypography variant="h6" color="secondary">
          <Icon sx={{ fontWeight: "bold" }}>close</Icon>
        </MDTypography>
      </MDBox>
      <MDBox textAlign="center">
        <MDTypography
          component="h6"
          variant="button"
          fontWeight="medium"
          color={textColor}
        >
          {accountName}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <SideNavBarRoot
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {renderAccount}
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {routes.map((m) => {
          return <NavItem key={m.key} item={m}></NavItem>;
        })}
      </List>
    </SideNavBarRoot>
  );
}
