import pxToRem from "../../functions/pxToRem";
import palette from "../../base/palette";
import typography from "../../base/typography";

const { common, text, info, secondary } = palette;
const { size } = typography;

const contained = {
  base: {
    backgroundColor: common?.white,
    minHeight: pxToRem(37),
    color: text?.primary,
    padding: `${pxToRem(9)} ${pxToRem(24)}`,

    "&:hover": {
      backgroundColor: common?.white,
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(29),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size?.xl,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(44),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size?.sm,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: info?.main,

    "&:hover": {
      backgroundColor: info?.main,
    },

    "&:focus:not(:hover)": {
      backgroundColor: info?.focus,
    },
  },

  secondary: {
    backgroundColor: secondary?.focus,

    "&:hover": {
      backgroundColor: secondary?.main,
    },

    "&:focus:not(:hover)": {
      backgroundColor: secondary?.focus,
    },
  },
};

export default contained;
