import {
  MDCard,
  MDCardBody,
  MDCardHeader,
  MDCardHeaderProps,
} from "../../../components/MDCard";
import { ResponsiveLine, Serie } from "@nivo/line";
import moment from "moment";
import MDTypography from "../../../components/MDTypography";
import { Divider } from "@mui/material";
import MDBox from "../../../components/MDBox";

interface LineCardProps extends MDCardHeaderProps {
  data: Serie[];
  height?: string | number;
  title?: string;
}

export default function LineCard(props: LineCardProps) {
  const { data, height, title, bgColor, ...rest } = props;

  return (
    <MDCard>
      <MDCardHeader bgColor={bgColor} {...rest}>
        <div style={{ height: height ?? 250 }}>
          <ResponsiveLine
            theme={{
              textColor: "white",
              axis: {
                ticks: {
                  text: {
                    fill: "white",
                  },
                },
              },
            }}
            colors={{ scheme: "pastel2" }}
            data={data}
            pointSize={10}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              reverse: false,
              nice: true,
            }}
            margin={{ top: 40, right: 40, bottom: 60, left: 80 }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickPadding: 20,
              tickRotation: -15,
            }}
            axisBottom={{
              renderTick: (tick) => {
                let simp = data[0]?.data[tick.tickIndex];
                return (
                  <text
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                      fill: "white",
                      fontSize: 12,
                    }}
                  >
                    <tspan x={tick.x} y={tick.y + 22} fontSize={10}>
                      {`#${simp?.version}`}
                    </tspan>
                    <tspan x={tick.x} y={tick.y + 35} fontSize={10}>
                      {moment(simp?.created_date).fromNow()}
                    </tspan>
                  </text>
                );
              },
            }}
            tooltip={(tip) => {
              let p = tip.point.data as any;
              return (
                <MDBox
                  p={2}
                  sx={{ backgroundColor: tip.point.serieColor }}
                  borderRadius="md"
                >
                  <MDTypography variant="subtitle2" color="dark">
                    {`#${p.x}(${p.version})-${moment(p.created_date).format(
                      "YYYY/MM/DD"
                    )}`}
                  </MDTypography>
                  <MDTypography variant="h6" color="dark">
                    {p.y}
                  </MDTypography>
                </MDBox>
              );
            }}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: -40,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </MDCardHeader>
      <MDCardBody>
        <MDTypography>{title}</MDTypography>
      </MDCardBody>
      <Divider />
    </MDCard>
  );
}
