import Button, { ButtonTypeMap } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { forwardRef, Ref } from "react";
import { OverrideProps } from "@mui/material/OverridableComponent";
import { useMaterialUIController } from "../../hooks/useMaterialUIController";

export type MDButtonProps<
  D extends React.ElementType = ButtonTypeMap["defaultComponent"],
  P = {
    circular?: boolean;
    iconOnly?: boolean;
  }
> = OverrideProps<ButtonTypeMap<P, D>, D>;

export interface MDButtonRootProps extends MDButtonProps {
  darkMode?: boolean;
}

const MDButtonRoot = styled(Button)<{ ownerState: MDButtonRootProps }>(
  ({ theme, ownerState }) => {
    const { palette, functions, borders } = theme;
    const { color, variant, size, circular, iconOnly, darkMode } = ownerState;
    const { common, text, transparent, grey } = palette;
    const { white } = common;
    const { boxShadow, linearGradient, pxToRem, rgba } = functions;
    const { borderRadius } = borders;

    const effectiveMainColor =
      color === undefined || color === "white"
        ? white
        : color === "inherit"
        ? "inherit"
        : palette[color].main;

    const effectiveFocusColor =
      color === undefined || color === "white"
        ? white
        : color === "inherit"
        ? "inherit"
        : palette[color].focus;

    const containedStyles = () => {
      const backgroundValue = effectiveMainColor;
      const focusedBackgroundValue = effectiveFocusColor;
      const boxShadowValue =
        color === undefined
          ? "none"
          : `${boxShadow(
              [0, 3],
              [3, 0],
              effectiveMainColor,
              0.15
            )}, ${boxShadow(
              [0, 3],
              [1, -2],
              effectiveMainColor,
              0.2
            )}, ${boxShadow([0, 1], [5, 0], effectiveMainColor, 0.15)}`;
      const hoveredBoxShadowValue =
        color === undefined
          ? "none"
          : `${boxShadow(
              [0, 14],
              [26, -12],
              effectiveMainColor,
              0.4
            )}, ${boxShadow(
              [0, 4],
              [23, 0],
              effectiveMainColor,
              0.15
            )}, ${boxShadow([0, 8], [10, -5], effectiveMainColor, 0.2)}`;

      let colorValue = white;
      if (
        !darkMode &&
        (color === "white" || color === "light" || color === undefined)
      ) {
        colorValue = text.primary;
      } else if (
        darkMode &&
        (color === "white" || color === "light" || color === undefined)
      ) {
        colorValue = grey[600];
      }

      let focusedColorValue = white;
      if (color === "white") {
        focusedColorValue = text.primary;
      } else if (color === "primary" || color === "error" || color === "dark") {
        focusedColorValue = white;
      }

      return {
        background: backgroundValue,
        color: colorValue,
        boxShadow: boxShadowValue,

        "&:hover": {
          backgroundColor: backgroundValue,
          boxShadow: hoveredBoxShadowValue,
        },

        "&:focus:not(:hover)": {
          backgroundColor: focusedBackgroundValue,
          boxShadow:
            color !== undefined
              ? boxShadow([0, 0], [0, 3.2], effectiveMainColor, 0.5)
              : boxShadow([0, 0], [0, 3.2], white, 0.5),
        },

        "&:disabled": {
          backgroundColor: backgroundValue,
          color: focusedColorValue,
        },
      };
    };

    const outlinedStyles = () => {
      // background color value
      const backgroundValue =
        color === undefined ? transparent.main : rgba(white, 0.1);
      // color value
      const colorValue = effectiveMainColor;

      // boxShadow value
      const boxShadowValue =
        color !== undefined
          ? boxShadow([0, 0], [0, 3.2], effectiveMainColor, 0.5)
          : boxShadow([0, 0], [0, 3.2], white, 0.5);

      // border color value
      let borderColorValue =
        color === undefined ? rgba(white, 0.75) : effectiveMainColor;

      return {
        background: backgroundValue,
        color: colorValue,
        borderColor: borderColorValue,

        "&:hover": {
          background: transparent.main,
          borderColor: colorValue,
        },

        "&:focus:not(:hover)": {
          background: transparent.main,
          boxShadow: boxShadowValue,
        },

        "&:active:not(:hover)": {
          backgroundColor: colorValue,
          color: white,
          opacity: 0.85,
        },

        "&:disabled": {
          color: colorValue,
          borderColor: colorValue,
        },
      };
    };
    const gradientStyles = () => {
      // background value
      const backgroundValue =
        color === "white" || color === undefined || color === "inherit"
          ? white
          : linearGradient(
              palette[color].gradient?.main,
              palette[color].gradient?.state
            );

      // boxShadow value
      const boxShadowValue =
        color !== undefined
          ? `${boxShadow(
              [0, 3],
              [3, 0],
              effectiveMainColor,
              0.15
            )}, ${boxShadow(
              [0, 3],
              [1, -2],
              effectiveMainColor,
              0.2
            )}, ${boxShadow([0, 1], [5, 0], effectiveMainColor, 0.15)}`
          : "none";

      const hoveredBoxShadowValue =
        color !== undefined
          ? `${boxShadow(
              [0, 14],
              [26, -12],
              effectiveMainColor,
              0.4
            )}, ${boxShadow(
              [0, 4],
              [23, 0],
              effectiveMainColor,
              0.15
            )}, ${boxShadow([0, 8], [10, -5], effectiveMainColor, 0.2)}`
          : "none";

      let colorValue = white;

      if (color === "white") {
        colorValue = text.primary;
      } else if (color === "light") {
        colorValue = palette.dark.gradient?.state ?? "";
      }

      return {
        background: backgroundValue,
        color: colorValue,
        boxShadow: boxShadowValue,

        "&:hover": {
          boxShadow: hoveredBoxShadowValue,
        },

        "&:focus:not(:hover)": {
          boxShadow: boxShadowValue,
        },

        "&:disabled": {
          background: backgroundValue,
          color: colorValue,
        },
      };
    };

    const textStyles = () => {
      const colorValue = effectiveMainColor;
      const focusedColorValue = effectiveFocusColor;

      return {
        color: colorValue,

        "&:hover": {
          color: focusedColorValue,
        },

        "&:focus:not(:hover)": {
          color: focusedColorValue,
        },
      };
    };

    const circularStyles = () => ({
      borderRadius: borderRadius.section,
    });

    const iconOnlyStyles = () => {
      let sizeValue = pxToRem(38);

      if (size === "small") {
        sizeValue = pxToRem(25.4);
      } else if (size === "large") {
        sizeValue = pxToRem(52);
      }

      let paddingValue = `${pxToRem(11)} ${pxToRem(11)} ${pxToRem(10)}`;

      if (size === "small") {
        paddingValue = pxToRem(4.5);
      } else if (size === "large") {
        paddingValue = pxToRem(16);
      }

      return {
        width: sizeValue,
        minWidth: sizeValue,
        height: sizeValue,
        minHeight: sizeValue,
        padding: paddingValue,

        "& .material-icons": {
          marginTop: 0,
        },

        "&:hover, &:focus, &:active": {
          transform: "none",
        },
      };
    };

    return {
      ...(variant === "contained" && containedStyles()),
      ...(variant === "outlined" && outlinedStyles()),
      ...(variant === "gradient" && gradientStyles()),
      ...(variant === "text" && textStyles()),
      ...(circular && circularStyles()),
      ...(iconOnly && iconOnlyStyles()),
    };
  }
);

const MDButton = forwardRef(
  (props: MDButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { color, variant, size, circular, iconOnly, children, ...rest } =
      props;
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{
          color,
          variant,
          size,
          circular,
          iconOnly,
          darkMode,
        }}
      >
        {children}
      </MDButtonRoot>
    );
  }
);

export default MDButton;
