import { useState } from "react";
import { Data } from "../../dtos/predict-response";
import useRoot from "../../hooks/useRoot";

const useAction = () => {
  const [input, setInput] = useState("");
  const [answers, setAnswers] = useState<Data>();
  const [searchQuestionDto, setSearchQuestionDto] = useState<string[]>([]);
  const { setDoingBusyWork } = useRoot();

  return {
    searchQuestionDto,
    input,
    setSearchQuestionDto,
    answers,
    setInput,
    setAnswers,
    setDoingBusyWork,
  };
};

export default useAction;
