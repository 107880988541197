import { TextField } from "@mui/material"
import MDTooltip from "../../../../../../components/MDTooltip"

const EditableTextField = (props: any) => {
  const { handleCellEdit, params, globalTootip, loadTitle, setGlobalTootip } =
    props
  return (
    <MDTooltip
      title={globalTootip}
      onOpen={() => {
        loadTitle(params.row["correct_qid"]);
      }}
      onClose={() => setGlobalTootip("loading...")}
    >
      <TextField
        variant="standard"
        value={params.row.correct_qid}
        onChange={(e) => {
          handleCellEdit(
            params.row.id,
            params.row.status,
            Number(e.target.value),
            params.row.remark
          );
        }}
      />
    </MDTooltip>
  )
}

export default EditableTextField
