import {
  Divider,
  Icon,
  IconButton,
  IconButtonProps,
  Menu,
  Theme,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MDBox from "../components/MDBox";
import MDBreadcrumbs from "../components/MDBreadcrumbs";
import MDNotificationItem from "../components/MDNotificationItem";
import MDTypography from "../components/MDTypography";
import useAuth from "../hooks/useAuth";
import {
  setMiniSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from "../hooks/useMaterialUIController";

interface AppNavBarProps {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

export default function AppNavBar(props: AppNavBarProps) {
  const { absolute, light, isMini } = props;
  const [navbarType, setNavbarType] = useState<
    "absolute" | "sticky" | "static"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const accountMenu = () => (
    <Menu
      open={anchorEl !== null}
      anchorEl={anchorEl}
      //anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={() => setAnchorEl(null)}
      sx={{ mt: 2 }}
    >
      <MDNotificationItem
        icon={<Icon>manage_accounts </Icon>}
        title="My Profile"
      />
      <Divider />
      <MDNotificationItem
        icon={<Icon>logout</Icon>}
        title="Logout"
        onClick={() => auth.signout()}
      />
    </Menu>
  );

  return (
    <MuiAppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => {
        const {
          palette,
          boxShadows,
          functions,
          transitions,
          breakpoints,
          borders,
        } = theme;
        const { dark, text, transparent, background } = palette;
        const { navbarBoxShadow } = boxShadows;
        const { rgba, pxToRem } = functions;
        const { borderRadius } = borders;
        return {
          boxShadow: transparentNavbar || absolute ? "none" : navbarBoxShadow,
          backdropFilter:
            transparentNavbar || absolute
              ? "none"
              : `saturate(200%) blur(${pxToRem(30)})`,
          backgroundColor:
            transparentNavbar || absolute
              ? `${transparent.main} !important`
              : rgba(darkMode ? background.default : palette.common.white, 0.8),

          color: light
            ? palette.common.white
            : transparentNavbar
            ? text.primary
            : dark.main,
          top: absolute ? 0 : pxToRem(12),
          minHeight: pxToRem(75),
          display: "grid",
          alignItems: "center",
          borderRadius: borderRadius.xl,
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
          paddingRight: absolute ? pxToRem(8) : 0,
          paddingLeft: absolute ? pxToRem(16) : 0,

          "& > *": {
            transition: transitions.create("all", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
          "& .MuiToolbar-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.up("sm")]: {
              minHeight: "auto",
              padding: `${pxToRem(4)} ${pxToRem(16)}`,
            },
          },
        };
      }}
    >
      <TopNavBar>
        <TopBarItem color="inherit" mb={{ xs: 1, md: 0 }}>
          <MDBreadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </TopBarItem>
        {isMini ? null : (
          <TopBarItem>
            <MDBox color={light ? "white" : "inherit"}>
              <TopBarIcon
                light={light}
                darkMode={darkMode}
                transparentNavbar={transparentNavbar}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                account_circle
              </TopBarIcon>
              <TopBarIcon
                mobile
                light={light}
                darkMode={darkMode}
                transparentNavbar={transparentNavbar}
                onClick={() => setMiniSidenav(dispatch, !miniSidenav)}
              >
                {miniSidenav ? "menu_open" : "menu"}
              </TopBarIcon>

              <TopBarIcon
                light={light}
                darkMode={darkMode}
                transparentNavbar={transparentNavbar}
                aria-controls="notification-menu"
                aria-haspopup="true"
                //onClick={}
              >
                notifications
              </TopBarIcon>
            </MDBox>
          </TopBarItem>
        )}
        {accountMenu()}
      </TopNavBar>
    </MuiAppBar>
  );
}

function TopNavBar(props: { children: React.ReactNode }) {
  return (
    <MuiToolbar
      sx={(theme) => {
        return {
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          pt: 0.5,
          pb: 0.5,

          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            paddingTop: "0",
            paddingBottom: "0",
          },
        };
      }}
    >
      {props.children}
    </MuiToolbar>
  );
}

function TopBarItem(props: {
  isMini?: boolean;
  children: React.ReactNode;
  [rest: string]: any;
}) {
  return (
    <MDBox
      {...props.rest}
      sx={(theme) => {
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",

          [theme.breakpoints.up("md")]: {
            justifyContent: props.isMini ? "space-between" : "stretch",
            width: props.isMini ? "100%" : "max-content",
          },

          [theme.breakpoints.up("xl")]: {
            justifyContent: "stretch !important",
            width: "max-content !important",
          },
        };
      }}
    >
      {props.children}
    </MDBox>
  );
}

interface TopBarIconProps extends IconButtonProps {
  mobile?: boolean;
  light?: boolean;
  darkMode?: boolean;
  transparentNavbar?: boolean;
}
function TopBarIcon(props: TopBarIconProps) {
  const { mobile, light, darkMode, transparentNavbar, children, ...rest } =
    props;

  const normalStyle = (theme: Theme) => {
    return {
      px: 1,
      "& .material-icons, .material-icons-round": {
        fontSize: `${theme.typography.size.xl} !important`,
      },
      "& .MuiTypography-root": {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "inline-block",
          lineHeight: 1.2,
          ml: 0.5,
        },
      },
    };
  };

  const mobileStyle = (theme: Theme) => {
    return {
      display: "inline-block",
      lineHeight: 0,

      [theme.breakpoints.up("xl")]: {
        display: "none",
      },
    };
  };

  return (
    <IconButton
      {...rest}
      size="small"
      disableRipple
      sx={(theme) => (mobile ? mobileStyle(theme) : normalStyle(theme))}
    >
      <Icon
        sx={(theme) => {
          let colorValue =
            light || darkMode
              ? theme.palette.common.white
              : theme.palette.dark.main;
          if (transparentNavbar && !light) {
            colorValue = darkMode
              ? theme.functions.rgba(theme.palette.text.primary, 0.6)
              : theme.palette.text.primary;
          }
          return {
            color: colorValue,
          };
        }}
      >
        {children}
      </Icon>
    </IconButton>
  );
}
