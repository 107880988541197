import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MDBox from "../components/MDBox";
import {
  useMaterialUIController,
  setLayout,
} from "../hooks/useMaterialUIController";
import AppNavBar from "./AppBar";

export default function Main(props: { children: React.ReactNode }) {
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <MDBox
      sx={(theme) => {
        return {
          p: 3,
          position: "relative",
          [theme.breakpoints.up("xl")]: {
            marginLeft: controller.miniSidenav
              ? theme.typography.pxToRem(120)
              : theme.typography.pxToRem(274),
            transition: theme.transitions.create(
              ["margin-left", "margin-right"],
              {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.standard,
              }
            ),
          },
        };
      }}
    >
      <AppNavBar />
      {props.children}
    </MDBox>
  );
}
