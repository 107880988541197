import typography from "../base/typography";
import borders from "../base/borders";
import colors from "../base/palette";
import pxToRem from "../functions/pxToRem";
import boxShadows from "../base/boxShadows";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark, grey } = colors;
const { tabsBoxShadow } = boxShadows;
const tab = {
  styleOverrides: {
    root: {
      position: "relative" as "relative",
      //backgroundColor: grey?.[100],
      //borderRadius: borderRadius.xl,
      //minHeight: "unset",
      padding: pxToRem(4),
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius: borderRadius.lg,
      backgroundColor: colors.common?.white,
      boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
    },
  },
};
const tabs = {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row" as "row",
      flex: "1 1 auto",
      textAlign: "center" as "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size?.md,
      fontWeight: fontWeightRegular,
      textTransform: "none" as "none",
      lineHeight: "inherit",
      padding: pxToRem(4),
      borderRadius: borderRadius.lg,
      color: `${dark.main} !important`,
      opacity: "1 !important",

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};
export { tab, tabs };
