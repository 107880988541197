import { Serie } from "@nivo/line";
import { useEffect, useMemo, useState } from "react";
import { addToRange, DateRange } from "react-day-picker";
import { ModelPerformanceDto } from "../../dtos/model-performance-response";
import {
  QuestionsBehaviourType,
  QuestionsBehaviourTypeDto,
} from "../../dtos/user-questions-response";
import useRoot from "../../hooks/useRoot";
import { Get } from "../../uilts/request";
import { format, isEqual } from "date-fns";
import { clone } from "ramda";

export const useAction = () => {
  const { setDoingBusyWork } = useRoot();
  const [models, setModels] = useState<ModelPerformanceDto[]>([]);

  const [accuracySerie, setAccuracySerie] = useState<Array<Serie>>();
  const [avgSerie, setAvgSerie] = useState<Array<Serie>>();
  const [countSerie, setCountSerie] = useState<Array<Serie>>();

  const date = new Date();
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(new Date().getMonth() - 1);

  const defaultSelected: DateRange = {
    from: lastMonthDate,
    to: date,
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  const [selectedMonth, setSelectedMonth] = useState<Date>(lastMonthDate);

  function handleDayClick(day: Date) {
    const newRange = addToRange(day, range);
    setRange(newRange);
    setSelectedMonth(day);
  }

  const [lastTimeSelected, setLastTimeSelected] = useState<
    DateRange | undefined
  >(defaultSelected);

  const [behaviourTypeCount, setBehaviourTypeCount] = useState<
    QuestionsBehaviourTypeDto[]
  >([]);

  const [behaviourTypeList, setBehaviourTypeList] = useState<
    QuestionsBehaviourType[]
  >([]);

  const [open, setOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [title, setTitle] = useState<string>("");

  const [timeTitle, setTimeTitle] = useState<string>("");

  const [isLoading, setIsloading] = useState<boolean>(false);

  const [isLoadDown, setIsLoadDown] = useState<boolean>(false);

  const loadData = () => {
    setDoingBusyWork(true);
    Get<ModelPerformanceDto[]>("/api/v1/models/get").then((response) => {
      setModels(response ?? []);
      setDoingBusyWork(false);
    });
    setDoingBusyWork(false);
  };

  const loadBehaviourEnum = () => {
    setIsloading(true);
    Get<QuestionsBehaviourType[]>("/api/v1/faqs/questions/behaviour/type")
      .then((response) => {
        if (response) {
          setTimeout(() => {
            setBehaviourTypeList(response);
            setIsloading(false);
          }, 500);
        }
      })
      .catch((err) => {
        setIsloading(false);
      });
  };

  const loadBehaviourCount = async (
    startTime: string,
    endTime: string,
    item: QuestionsBehaviourType,
    index: number,
    maxIndex: number
  ) => {
    await Get<number>(
      `/api/v1/faqs/questions/behaviour/count?StartTime=${startTime}&EndTime=${endTime}&BehaviourType=${item.value}`
    ).then(async (response) => {
      if (typeof response === "number") {
        setBehaviourTypeCount((prev) => {
          const newVlue = prev.filter((x) => x);
          const findIndex = newVlue.findIndex((x) => x.value === item.value);
          if (findIndex === -1)
            newVlue.push({
              count: response,
              value: item.value,
              text: item.text,
              description: item.description,
            });
          else {
            newVlue[findIndex].count = response;
          }
          return newVlue;
        });
        if (index === maxIndex - 1) setIsLoadDown(true);
      }
    });
  };

  const buildSeries = (fields: (keyof ModelPerformanceDto)[]): Serie[] => {
    return fields.map((field) => {
      const result = {
        id: field.startsWith("rasa")
          ? "ModelR"
          : field.startsWith("anyq")
          ? "ModelQ"
          : field.startsWith("model3")
          ? "Model3"
          : "Records",
        color: "hsl(255, 100%, 100%)",
        data: models
          .sort((a, b) => {
            return a.created_date > b.created_date
              ? 1
              : a.created_date < b.created_date
              ? -1
              : 0;
          })
          .map((m) => {
            return {
              x: m.id,
              y: m[field],
              version: m.version,
              created_date: m.created_date,
            };
          }),
      };
      return result;
    });
  };

  const handleClickOpen = () => {
    const nowTime = clone(range);
    setLastTimeSelected(nowTime);
    setOpen(true);
  };

  const handleClose = (text: string) => {
    if (text === "Cancel") {
      setOpen(false);
      setTimeTitle(text);
    } else {
      if (range?.from && range?.to) {
        if (range?.from === undefined || range?.to === undefined) {
          setTitle("未选择日期区间!");
          setSnackbarOpen(true);
          return;
        } else if (isEqual(range.from, range.to)) {
          setTitle("所选日期区间有错误,最少选择一天!");
          setSnackbarOpen(true);
          return;
        }
        setTimeTitle(text);
        setOpen(false);
      }
    }
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const pieData = useMemo(() => {
    if (isLoadDown) {
      return behaviourTypeCount.map((x) => ({
        id: x.description,
        label: x.description,
        value: x.count,
      }));
    }
    return [];
  }, [isLoadDown, behaviourTypeCount]);

  useEffect(() => {
    loadData();
    loadBehaviourEnum();
  }, []);

  useEffect(() => {
    if (timeTitle === "Cancel" && !open) {
      setTimeTitle("Clean");
      setRange(lastTimeSelected);
    }
  }, [timeTitle, open]);

  useEffect(() => {
    if (
      behaviourTypeList.length > 0 &&
      !open &&
      (timeTitle === "Save" || timeTitle === "")
    ) {
      (async function loadData() {
        setIsLoadDown(false);
        if (range?.from !== undefined && range?.to !== undefined) {
          for (const key in behaviourTypeList) {
            await loadBehaviourCount(
              format(range?.from, "yyyy-MM-dd"),
              format(range?.to, "yyyy-MM-dd"),
              behaviourTypeList[key],
              Number(key),
              behaviourTypeList.length
            );
          }
        }
      })();
    }
  }, [range, behaviourTypeList, open, timeTitle]);

  useEffect(() => {
    if (models.length > 0) {
      setAccuracySerie(
        buildSeries([
          "rasa_overall_acc",
          "anyq_overall_acc",
          "model3_overall_acc",
        ])
      );
      setAvgSerie(
        buildSeries([
          "rasa_avg_intent_acc",
          "anyq_avg_intent_acc",
          "model3_avg_intent_acc",
        ])
      );
      setCountSerie(buildSeries(["num_of_records"]));
    }
  }, [models]);

  return {
    accuracySerie,
    avgSerie,
    countSerie,
    behaviourTypeCount,
    open,
    handleClickOpen,
    handleClose,
    range,
    setRange,
    handleSnackbarClose,
    snackbarOpen,
    title,
    isLoading,
    loadBehaviourEnum,
    lastMonthDate,
    selectedMonth,
    handleDayClick,
    pieData,
    isLoadDown,
  };
};
