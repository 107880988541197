import MDBox from "../../../../components/MDBox"
import MDButton from "../../../../components/MDButton"
import { MDCardAction } from "../../../../components/MDCard"
import { Post } from "../../../../uilts/request"

const ReviewFooter = (props: any) => {
  const { updateQuestionDtos, setUpdateQuestionDtos, loadData } = props

  const onSaveClick = () => {
    if (updateQuestionDtos.length) {
      Post("/api/v1/faqs/correct", {
        updatedQuestions: updateQuestionDtos,
      }).then((data) => {
        onFinishedSaving()
      })
    }
  }

  const onFinishedSaving = () => {
    loadData()
    setUpdateQuestionDtos([])
  }

  return (
    <MDCardAction>
      <MDBox>
        <MDButton
          variant={updateQuestionDtos.length ? "gradient" : "text"}
          color="success"
          disabled={!updateQuestionDtos.length}
          fullWidth={true}
          onClick={onSaveClick}
        >
          Save
        </MDButton>
      </MDBox>
    </MDCardAction>
  )
}

export default ReviewFooter
