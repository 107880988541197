export interface FaqResponse<T> {
  code: number
  msg: string
  data: T
}

export interface Data {
  rasa_answers: Answer[]
  anyq_answers: Answer[]
  model_3_answers: Answer[]
  model_v_answers: Answer[]
}

export interface Answer {
  qid: number
  confidence: number
  answer_content: string
  title: string
}

export interface AnswerProps {
  answer: Answer
}

export enum ResponseCode {
  ok = 200,
  unauthorized = 401,
  internalservererror = 500
}
