import { Answer, Data } from "../../dtos/predict-response";
import { Divider, Grid } from "@mui/material";
import { MDCard, MDCardBody, MDCardHeader } from "../../components/MDCard";
import MDTypography from "../../components/MDTypography";
import { Get } from "../../uilts/request";
import AnswerElement from "./components/answer-element";
import SearchElement from "./components/search-element";
import { AppBox, Welcome } from "./index.styles";
import useAction from "./hook";

export default function HomePage() {
  const {
    searchQuestionDto,
    input,
    setSearchQuestionDto,
    answers,
    setInput,
    setAnswers,
    setDoingBusyWork,
  } = useAction();

  const onSubmit = (questionName: string) => {
    setDoingBusyWork(true);
    let historyQuestion = searchQuestionDto.filter((x) => x != null);

    let transitInput = "";

    if (historyQuestion.find((x) => x === questionName)) {
      setInput(questionName);
      transitInput = questionName;
    } else {
      if (!!input) {
        historyQuestion.push(input);
        setSearchQuestionDto(historyQuestion);
        transitInput = input;
      }
    }
    !!transitInput &&
      Get<Data>(`/api/v1/faqs/predict/${transitInput}`).then((response) => {
        setAnswers(response);
        // setInput("");
      });

    setDoingBusyWork(false);
  };

  const modelElement = (name: string, answers?: Answer[]) => {
    return (
      <MDCard textAlign={"left"}>
        <MDCardHeader bgColor="info">
          <MDTypography variant="h6">{name}</MDTypography>
        </MDCardHeader>
        <MDCardBody>
          {answers &&
            answers.map((x, i) => {
              return (
                <div key={i}>
                  <AnswerElement key={i} answer={x}></AnswerElement>
                  <Divider />
                </div>
              );
            })}
        </MDCardBody>
      </MDCard>
    );
  };

  return (
    <AppBox>
      <Welcome>Welcome to Smart-Faq</Welcome>
      <SearchElement
        value={input}
        onSubmit={onSubmit}
        onChange={setInput}
        historyQuestion={searchQuestionDto}
      />
      {answers && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={3}>
            {modelElement("Model R", answers.rasa_answers)}
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            {modelElement("Model Q", answers.anyq_answers)}
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            {modelElement("Model 3", answers.model_3_answers)}
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            {modelElement("Model V", answers.model_v_answers)}
          </Grid>
        </Grid>
      )}
    </AppBox>
  );
}
