import { Card, Divider, Icon } from "@mui/material";
import { ReactNode } from "react";
import { PalettePrimordialColor } from "../../assets/defaultTheme/module";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

export interface MDIconCardProps {
  icon: string;
  title: any;
  count: any;
  color?: PalettePrimordialColor;
  footer?: ReactNode;
}

export default function MDIconCard(props: MDIconCardProps) {
  const { color, icon, title, count, footer } = props;
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4">{count}</MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      {footer && (
        <MDBox pb={2} px={2}>
          {footer}
        </MDBox>
      )}
    </Card>
  );
}
